import { Container, Box, Grid, GridItem, Spinner, Text, VStack, Heading, Flex } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useExerciseSetInTopic } from '../../hooks/swr';
import useExerciseSetProgress from '../../hooks/useExerciseSetProgress';
import { exerciseToAnswerString } from '../../utils/exercise';
import TaskNumber from '../atoms/TaskNumber';
import AnswerRow from '../molecules/AnswerRow';
import Calculator from '../organisms/Calculator';
import FinishedSet from '../organisms/FinishedSet';

const TaskView: React.FC = () => {
    const { topic, exerciseKey } = useParams<{ topic: string; exerciseKey: string }>();

    const exerciseSet = useExerciseSetInTopic(exerciseKey, topic);

    const { currentExercise, validate, progress, onExerciseClick, state, onNext, onPrev } = useExerciseSetProgress(
        exerciseSet?.exercises ?? [],
        exerciseSet?.id ?? -1,
        topic,
    );

    if (!exerciseSet || !currentExercise) {
        return <Spinner />;
    }

    const correctAnswers = progress.filter((item) => item.state == 'correct').length;

    return (
        <Container maxW="1024px" pt="50px" px={0}>
            <Grid templateRows="repeat(4, 1fr)" templateColumns="repeat(5, 1fr)" minH="40vh" gap={4}>
                <GridItem rowSpan={4} colSpan={1}>
                    <VStack
                        spacing="8px"
                        py="10px"
                        px="16px"
                        bg="primaryLight"
                        borderRadius="8"
                        width="fit-content"
                        align="flex-start"
                        height="fit-content"
                    >
                        <Heading
                            fontSize="20px"
                            color="white"
                            mb="8px"
                            textAlign="center"
                            alignSelf="center"
                            fontWeight="extrabold"
                        >
                            {exerciseSet.title}
                        </Heading>
                        {Object.keys(progress).map((key, i) => (
                            <TaskNumber
                                key={key}
                                taskName={`Oppgave ${i + 1}`}
                                taskStatus={progress[i].state}
                                currentExcercise={progress[i].exercise.id == currentExercise.exercise.id}
                                onClick={() => onExerciseClick(i)}
                            />
                        ))}
                    </VStack>
                </GridItem>
                {state === 'finished' ? (
                    <GridItem colSpan={4} rowSpan={3}>
                        <FinishedSet
                            fullScore={correctAnswers == progress.length}
                            header={`${correctAnswers} av ${progress.length} riktige`}
                        />
                    </GridItem>
                ) : (
                    <>
                        <GridItem rowSpan={2} colSpan={4}>
                            <Flex
                                height="100%"
                                width="85%"
                                mx="auto"
                                alignItems="flex-start"
                                justify="center"
                                flexDir="column"
                            >
                                <Text fontSize="xl">Oppgave {currentExercise.exercise.id + 1}</Text>
                                <Text
                                    fontSize="28px"
                                    fontWeight="semibold"
                                    fontFamily="Lato"
                                    textAlign="left"
                                    left="0"
                                    right="0"
                                >
                                    {currentExercise.exercise.description}
                                </Text>
                            </Flex>
                        </GridItem>
                        <GridItem colSpan={4}>
                            <VStack spacing={1}>
                                {currentExercise.state === 'wrong' && (
                                    <Text>Riktig svar: {exerciseToAnswerString(currentExercise.exercise)}</Text>
                                )}
                                {currentExercise.state === 'missingUnit' && <Text>Husk benevning!</Text>}
                                <AnswerRow
                                    onClick={validate}
                                    animate={state == 'success'}
                                    value={currentExercise.value}
                                    onNext={onNext}
                                    onPrev={onPrev}
                                    exerciseState={currentExercise.state}
                                />
                            </VStack>
                        </GridItem>
                        <GridItem colSpan={4}>
                            <Box position="relative" width={['100%', '85%']} height="100%" mx="auto">
                                <Box position="absolute">
                                    <Calculator unitType="s" />
                                </Box>
                            </Box>
                        </GridItem>
                    </>
                )}
            </Grid>
        </Container>
    );
};

export default TaskView;
