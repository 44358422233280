import useSWR from 'swr';
import { ExerciseSet, ExerciseSetOption, ExerciseTopic } from '../types/Exercises';
import { getExerciseSet, getExerciseSets, getExerciseTopics } from '../utils/cloudStorage';
import { logErrorToFirebase } from '../utils/firebase';

export const useExerciseTopics = (): ExerciseTopic[] => {
    const { data, error } = useSWR<ExerciseTopic[]>('topics', getExerciseTopics);
    if (!data || error) {
        return [];
    }

    return data;
};

export const useExerciseSetsForTopic = (topic: string): ExerciseSetOption[] => {
    const { data, error } = useSWR<ExerciseSetOption[]>(['topics/sets', topic], getExerciseSets);

    if (!data || error) {
        return [];
    }

    return data;
};

export const useExerciseSetInTopic = (setName: string, topic: string): ExerciseSet | null => {
    const { data, error } = useSWR<ExerciseSet>(['topics/sets', topic, setName], getExerciseSet);
    if (!data || error) {
        return null;
    }

    return data;
};

export const swrOnError = (err: Error, key: string): void => {
    logErrorToFirebase('swr_error', {
        key,
        error: err,
    });
};
