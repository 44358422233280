import React, { useContext, useEffect, useState } from 'react';
import firbeaseInstance from '../utils/firebase';
import firebase from 'firebase/app';
import { AuthState, SubscriptionData, UserDataFirestore } from '../types/User';
import { Spinner, useBoolean, Flex, VStack, Heading } from '@chakra-ui/react';

const AuthContext = React.createContext<AuthState | null>(null);

export const AuthProvider: React.FC = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);
    const [userData, setUserData] = useState<SubscriptionData>({ type: 'inactive', validSubscription: false });
    const [isLoading, { off, on }] = useBoolean(true);

    useEffect(() => {
        const removeObserver = firbeaseInstance.auth().onAuthStateChanged((user) => {
            on();
            setCurrentUser(user);
            off();
        });
        // on unmount remove observer
        return removeObserver;
    }, []);

    useEffect(() => {
        if (currentUser) {
            const removeObserver = firbeaseInstance
                .firestore()
                .collection('users')
                .doc(currentUser.uid)
                .onSnapshot((update) => {
                    const data: UserDataFirestore | undefined = update.data() as UserDataFirestore;

                    if (data != undefined) {
                        const validDateInMS = data.validUntil.seconds * 1000;

                        const isValid = Date.now() < validDateInMS;

                        setUserData({
                            type: 'active',
                            validUntil: new Date(validDateInMS),
                            validSubscription: isValid,
                            subscriptionType: 'forhåndsbetalt',
                        });
                    } else {
                        setUserData({
                            type: 'inactive',
                            validSubscription: false,
                        });
                    }
                });

            return removeObserver;
        }
    }, [currentUser]);

    return (
        <AuthContext.Provider value={{ user: currentUser, subscription: userData }}>
            {isLoading ? (
                <Flex h="100vh" align="center" flexDir="column" justify="center">
                    <VStack>
                        <Spinner size="xl" label="hei" />
                        <Heading size="l">Laster...</Heading>
                    </VStack>
                </Flex>
            ) : (
                children
            )}
        </AuthContext.Provider>
    );
};

export const useCurrentUser = (): firebase.User | null => {
    const context = useContext(AuthContext);

    if (!context) {
        throw Error('useCurrentUser must be used within a AuthProvider');
    }

    return context.user;
};

export const useIsSignedIn = (): boolean => {
    const context = useContext(AuthContext);

    if (!context) {
        throw Error('useIsSignedIn must be used within a AuthProvider');
    }

    return context.user != null;
};

export const useCurrentUserData = (): SubscriptionData => {
    const context = useContext(AuthContext);

    if (!context) {
        throw Error('useCurrentUserData must be used within a AuthProvider');
    }

    return context.subscription;
};

export const useCurrentUserHasValidSubscription = (): boolean => {
    const context = useContext(AuthContext);

    if (!context) {
        throw Error('useCurrentUserHasValidSubscription must be used within a AuthProvider');
    }

    return context.subscription.validSubscription;
};
