import React, { useContext, useEffect, useState } from 'react';
import { Spinner, useBoolean, Flex, VStack, Heading } from '@chakra-ui/react';
import { SubscriptionPlan } from '../types/Plans';
import { getAccessProducts } from '../utils/firebaseFunctions';
import { StaticState } from '../types/Context';

const StaticContext = React.createContext<StaticState | null>(null);

export const StaticProvider: React.FC = ({ children }) => {
    const [products, setProducts] = useState<SubscriptionPlan[]>([]);
    const [isLoading, { off, on }] = useBoolean(true);

    useEffect(() => {
        on();
        getAccessProducts().then((data) => {
            setProducts(data);
            off();
        });
    }, []);

    return (
        <StaticContext.Provider value={{ products }}>
            {isLoading ? (
                <Flex h="100vh" align="center" flexDir="column" justify="center">
                    <VStack>
                        <Spinner size="xl" label="hei" />
                        <Heading size="l">Laster...</Heading>
                    </VStack>
                </Flex>
            ) : (
                children
            )}
        </StaticContext.Provider>
    );
};

export const useProducts = (): SubscriptionPlan[] => {
    const context = useContext(StaticContext);

    if (!context) {
        throw Error('useProducts must be used within a StaticProvider');
    }

    return context.products;
};
