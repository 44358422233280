import { useEffect, useState } from 'react';
import { useCurrentUser } from '../context/AuthProvider';
import { OverallProgress } from '../types/Exercises';
import { getOverallProgress } from '../utils/firebaseFunctions';

export const useOverallProgress = () => {
    const currentUser = useCurrentUser();
    const [progress, setProgress] = useState<OverallProgress>({});

    useEffect(() => {
        if (currentUser) {
            getOverallProgress(currentUser.uid).then(setProgress);
        }
    }, [currentUser]);

    return progress;
};
