import React from 'react';
import { HStack, VStack } from '@chakra-ui/react';
import BasicInput from '../components/atoms/Input';
import { Form, FormikProps, withFormik } from 'formik';
import BasicButton from '../components/atoms/Button';
import { usePromoCode } from '../utils/firebaseFunctions';
import PromoCodeFeedback from '../components/atoms/PromoCodeFeedback';

interface IUsePromoValues {
    promo: string;
}

interface IUsePromoProps {
    onSuccess: () => void;
}

const InnerUsePromo: React.FC<FormikProps<IUsePromoValues>> = ({
    handleBlur,
    handleChange,
    errors,
    touched,
    isValid,
    isSubmitting,
    status,
}) => (
    <VStack>
        <Form style={{ width: '100%' }} autoComplete="off">
            <HStack align="flex-end" spacing={4}>
                <BasicInput
                    id="promo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="f.eks UNI-123456"
                    error={errors.promo}
                    isDisabled={isSubmitting}
                    formControl={{ isInvalid: touched.promo && !!errors.promo }}
                    flex="1"
                />
                <BasicButton
                    isFullWidth
                    disabled={!isValid || isSubmitting}
                    isLoading={isSubmitting}
                    loadingText="Verifiserer"
                    colorScheme="teal"
                    type="submit"
                    variant="secondary"
                    label="Bruk kode"
                    maxW="30%"
                />
            </HStack>
        </Form>
        <PromoCodeFeedback status={status} successMessage="En tilgangskode er sendt til din epost!" />
    </VStack>
);

const UsePromoForm = withFormik<IUsePromoProps, IUsePromoValues>({
    handleSubmit: async ({ promo }, { setSubmitting, props, setStatus }) => {
        const { onSuccess } = props;
        setSubmitting(true);
        const res = await usePromoCode(promo);

        switch (res.type) {
            case 'success':
                onSuccess();
                break;
            case 'error':
                setStatus(res.code);
                break;
        }

        setSubmitting(false);
    },
    validateOnMount: true,
})(InnerUsePromo);

export default UsePromoForm;
