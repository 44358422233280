import React, { useEffect, useState } from 'react';
import { AspectRatio, Box, Circle, Heading, HStack, useBreakpointValue, VStack } from '@chakra-ui/react';
import { useCurrentUser } from '../../context/AuthProvider';
import { getSolvedExerciseSetsPerWeek } from '../../utils/firebaseFunctions';
import { CheckIcon } from '@chakra-ui/icons';
import { ExerciseSetWeekProgress } from '../../types/Exercises';
import { ReactComponent as Illustration } from '../../static/Illustrations/the_world_is_mine.svg';
import HeroHeader from '../atoms/HeroHeader';
import moment from 'moment';

interface IUserProgressProps {}

const UserProgress: React.FC<IUserProgressProps> = ({}) => {
    const currentUser = useCurrentUser();
    const [weekStats, setWeekStats] = useState<ExerciseSetWeekProgress[]>([]);
    const maxCheckMarks = useBreakpointValue({ base: 4, md: 6, lg: 10 }) ?? 10;

    useEffect(() => {
        if (currentUser) {
            getSolvedExerciseSetsPerWeek(currentUser.uid).then(setWeekStats);
        }
    }, [currentUser]);

    if (!currentUser || weekStats.length == 0) {
        return null;
    }

    const date = moment();
    const prevWeek = moment().subtract(1, 'w');
    const currentWeekKey = date.year() + '-' + date.isoWeek();
    const prevWeekKey = prevWeek.year() + '-' + prevWeek.isoWeek();
    const currentState = weekStats.find((item) => item.key == currentWeekKey) ?? { nSolved: 0, key: currentWeekKey };
    const prevState = weekStats.find((item) => item.key == prevWeekKey) ?? { nSolved: 0, key: prevWeekKey };

    const deltaSolvedPrevWeek = currentState.nSolved - prevState.nSolved;

    const nCheckMarks = currentState.nSolved > maxCheckMarks ? maxCheckMarks : currentState.nSolved;
    const moreMarkNumbers = currentState.nSolved - maxCheckMarks;

    if (currentState.nSolved == 0 && prevState.nSolved == 0) {
        return null;
    }

    return (
        <Box>
            <HeroHeader text="Din fremgang" fontWeight="bold" mb="20px" />
            <Box width="100%" bg="primaryLight" px={['20px', '30px']} py="10px" borderRadius="12" mb="20px">
                <HStack justify="space-between">
                    <VStack align="flex-start" spacing="10px">
                        <Heading fontSize={['xl', '3xl']}>
                            {currentState.nSolved} oppgavesett fullført denne uken
                        </Heading>
                        <Heading fontSize={['lg', 'xl']}>
                            {deltaSolvedPrevWeek > 0
                                ? `Det er ${deltaSolvedPrevWeek} flere enn forrige uke, kjempebra!`
                                : `Det er ${Math.abs(deltaSolvedPrevWeek)} mindre enn forrige uke. Stå på!`}
                        </Heading>
                        <HStack>
                            {Array(nCheckMarks)
                                .fill('0')
                                .map((_, i) => (
                                    <Circle key={`icon-${i}`} size={['35px', '50px']} bg="primary" color="white">
                                        <CheckIcon fontSize={['md', '3xl']} />
                                    </Circle>
                                ))}
                            {moreMarkNumbers > 0 && (
                                <Circle
                                    size={['35px', '50px']}
                                    bg="primary"
                                    color="white"
                                    fontSize={['sm', 'md']}
                                    fontWeight="bold"
                                >{`+${moreMarkNumbers}`}</Circle>
                            )}
                        </HStack>
                    </VStack>
                    <AspectRatio w="96px" h="175px" ratio={16 / 9}>
                        <Illustration width="100px" style={{ objectFit: 'contain' }} />
                    </AspectRatio>
                </HStack>
            </Box>
        </Box>
    );
};

export default UserProgress;
