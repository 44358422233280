import { theme as defaultTheme, extendTheme } from '@chakra-ui/react';
import CustomDividerStyle from './DividerStyle';
import CustomColors from './CustomColors';
import CustomStyles from './CustomStyles';

// colors generated on https://colordesigner.io/, 5 shades and 5 tints??
const theme = extendTheme(
    {
        components: {
            Divider: CustomDividerStyle,
        },
        colors: CustomColors,
        styles: CustomStyles,
        fonts: {
            heading: 'Lato',
            body: 'Lato',
        },
    },
    defaultTheme,
);

export default theme;
