import { Box, Heading, Table, Tbody, Text, Td, Th, Thead, Tr, VStack, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import CustomColors from '../../theme/CustomColors';
import HeroHeader from './HeroHeader';

interface OmregningVeskeRulesProps {
    isMobile: boolean;
}

const OmregningVeskeTable: React.ElementType<OmregningVeskeRulesProps> = ({ isMobile }) => (
    <>
        <HeroHeader text="Omregning væske" fontSize={['32', '36']} fontWeight="bold" fontFamily="lato" pt="5" />
        <VStack pt={5}>
            <Table variant="simple" size={isMobile ? 'sm' : 'md'}>
                <Thead>
                    <Tr>
                        <Th textColor="white" fontSize="l">
                            FRA
                        </Th>
                        <Th textColor="white" fontSize="l">
                            TIL
                        </Th>
                        <Th textColor="white" fontSize="l">
                            METODE
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td>liter (l)</Td>
                        <Td>desiliter (dl)</Td>
                        <Td>Gange med 10 (1l x 10 = 10dl)</Td>
                    </Tr>
                    <Tr>
                        <Td>desiliter (dl)</Td>
                        <Td>milliliter (ml)</Td>
                        <Td>Gange med 100 (1dl x 100 = 100ml)</Td>
                    </Tr>
                    <Tr>
                        <Td>milliliter (ml)</Td>
                        <Td>desiliter (dl)</Td>
                        <Td>Dele med 100 (100ml / 100 = 1dl)</Td>
                    </Tr>
                    <Tr>
                        <Td>desiliter (dl)</Td>
                        <Td>liter (l)</Td>
                        <Td>Dele med 10 (10dl / 10 = 1l)</Td>
                    </Tr>
                </Tbody>
            </Table>
            <SimpleGrid columns={[1, 1, 2, 2]} gap={10} justifyItems="center" pt={5}>
                <Box
                    borderRadius="1rem"
                    w="fit-content"
                    border="1px"
                    bg={CustomColors.primaryLight}
                    borderColor="white"
                    padding="20px"
                >
                    <Heading textShadow="lg" size="md" textAlign="left" fontWeight="600" fontStyle="normal" mt="20px">
                        {'Tips til omgjøring til mindre enhet'}
                    </Heading>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'Når man skal gjøre om noe til en enhet som er mindre enn den man har, kan man ' +
                            'legge til tilsvarende nuller i tallet man ganger med bak den verdien man skal gjøre om fra.'}
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'For eksempel: hvis man skal regne 1liter om til dl, skal man gange med 10. I 10 ' +
                            'er det 1 null, da kan man legge til 1 null til 1 og får 10dl.'}
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'Hvis man starter med et tall som har et komma, må man begynne med å flytte kommaet mot høyre ' +
                            ' tilsvarende antall nuller som i tallet man skal gange med. Er det ikke flere tall etter komma, ' +
                            'kan man begynne å legge til nuller.'}
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'For eksempel: hvis man skal gjøre om 0.5dl til milliliter. Da skal man gange med 100 ' +
                            'som har 2 nuller og flytte kommaet i 0.5dl en plass til høyre - som blir 5. ' +
                            'Man har da 1 null igjen fra det man skulle gange med, men ingen tall bak komma. ' +
                            'Da legger man rett og slett bare til den nullen som i eksempelet over, og man får svaret 50ml.'}
                    </Text>
                </Box>
                <Box
                    borderRadius="1rem"
                    w="fit-content"
                    border="1px"
                    bg={CustomColors.primaryLight}
                    borderColor="white"
                    padding="20px"
                >
                    <Heading textShadow="lg" size="md" textAlign="left" fontWeight="600" fontStyle="normal" mt="20px">
                        {'Tips til omgjøring til større enhet'}
                    </Heading>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'Når man skal gjøre om noe til en enhet som er større enn den man har, kan man flytte komma ' +
                            'tilsvarende antall nuller i det man deler med.'}
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'For eksempel: hvis man skal regne 1000ml om til dl, skal man dele med 100. ' +
                            'I tallet 100 er det 2 nuller. Da kan man flytte komma 2 plasser til venstre, og ' +
                            '1000ml blir da 10,00dl eller 10dl.'}
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'I tilfellet hvor komma kan bli flyttet til venstre slik at det ikke er flere tall (desimal), ' +
                            'må man begynne å legge til nuller fra venstre.'}
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'For eksempel: hvis man skal regne 10ml om til dl, skal man dele med 100. ' +
                            'I tallet 100 er det 2 nuller. Da kan man flytte komma 2 plasser til venstre. ' +
                            'Når man har flyttet kommaet en plass til venstre får man 1,0, men neste gang man flytter kommaet' +
                            ' må man legge til en null fra venstre - da blir tallet gyldig. Man har da 2 nuller igjen, ' +
                            'og går fra 1,0 til 0,1dl.'}
                    </Text>
                </Box>
            </SimpleGrid>
        </VStack>
    </>
);

export default OmregningVeskeTable;
