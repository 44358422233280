/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/analytics';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_ANALYTICS,
};

// Initialize Firebase
const firbeaseInstance = firebase.initializeApp(firebaseConfig);

// Type Guard for Firebase error
export const isFirebaseError = (arg: any): arg is firebase.FirebaseError => arg.code !== undefined;

const analytics = firbeaseInstance.analytics();

export const logErrorToFirebase = (category: string, event: { [key: string]: any }): void => {
    analytics.logEvent(category, event);
};

export default firbeaseInstance;
