import React from 'react';
import { AspectRatio, Box, Heading, HStack, SimpleGrid, VStack } from '@chakra-ui/react';
import { ReactComponent as EducationSvg } from '../../static/Illustrations/education.svg';
import { ReactComponent as ReceiptSvg } from '../../static/Illustrations/receipt.svg';
import HeroHeader from '../atoms/HeroHeader';
import { useHistory } from 'react-router-dom';
import { useCurrentUserData } from '../../context/AuthProvider';

export const SubscriptionInfoCards: React.FC = ({}) => {
    const data = useCurrentUserData();
    const history = useHistory();

    const onUniversityAccessClick = () => {
        history.push('/universitetsavtaler');
    };

    const onGetPrivateAccessClick = () => {
        history.push('/kjop-tilgang');
    };

    if (data.validSubscription) {
        return null;
    }

    return (
        <Box mt="16">
            <HeroHeader text="Få tilgang til alt innhold" fontWeight="bold" mb="20px" />
            <SimpleGrid columns={[1, 2]} gap={6}>
                <Box
                    display="flex"
                    flex="1"
                    onClick={onUniversityAccessClick}
                    _hover={{ transform: ['none', 'none', 'scale(1.02)'] }}
                >
                    <Box width="100%" bg="primaryLight" px={['20px', '30px']} py="10px" borderRadius="12" mb="20px">
                        <HStack justify="space-between">
                            <VStack align="flex-start" spacing="10px">
                                <Heading fontSize={['xl', '3xl']}>Universitetsavtale</Heading>
                                <Heading fontSize={['md', 'lg']} fontWeight="normal">
                                    Vi har avtale med flere universiteter i Norge. Sjekk om ditt universitet har en
                                    avtale med oss.
                                </Heading>
                            </VStack>
                            <AspectRatio w="300px" h="175px" ratio={16 / 9}>
                                <EducationSvg width="300px" style={{ objectFit: 'scale-down' }} />
                            </AspectRatio>
                        </HStack>
                    </Box>
                </Box>
                <Box
                    display="flex"
                    flex="1"
                    onClick={onGetPrivateAccessClick}
                    _hover={{ transform: ['none', 'none', 'scale(1.02)'] }}
                >
                    <Box width="100%" bg="primaryLight" px={['20px', '30px']} py="10px" borderRadius="12" mb="20px">
                        <HStack justify="space-between">
                            <VStack align="flex-start" spacing="10px">
                                <Heading fontSize={['xl', '3xl']}>Kjøp tilgang selv</Heading>
                                <Heading fontSize={['md', 'lg']} fontWeight="normal">
                                    Du kan kjøpe tilgang i 3, 6 eller 12 måneder. Se mer informasjon.
                                </Heading>
                            </VStack>
                            <AspectRatio w="300px" h="175px" ratio={16 / 9}>
                                <ReceiptSvg width="300px" style={{ objectFit: 'scale-down' }} />
                            </AspectRatio>
                        </HStack>
                    </Box>
                </Box>
            </SimpleGrid>
        </Box>
    );
};

export default SubscriptionInfoCards;
