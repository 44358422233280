import React from 'react';
import { Flex, Container, Center, Text, Heading } from '@chakra-ui/react';
import SubscriptionsOverview from '../organisms/SubscriptionsOverview';

interface INonValidSubscriptionPageProps {}

const NonValidSubscriptionPage: React.FC<INonValidSubscriptionPageProps> = ({}) => {
    const url = window.location.href;

    return (
        <Flex direction="column">
            <Container maxW="1024px">
                <Center minH="200px" py="4" flexDir="column">
                    <Heading size="2xl" maxW={['100%', '60%']} textAlign="center">
                        Denne siden trenger et gyldig abonnement
                    </Heading>
                    <Text pt="2" maxW={['100%', '70%']} textAlign="center" fontSize="18px">
                        For å se innholdet på siden du forsøker å besøke trenger du et aktivt abonnement. Du kan rask få
                        tilgang til denne siden ved å velge et av alternativene nedenfor. Tilgang til siden vil være
                        tilgjengelig umiddelbart etter vellykket kjøp.
                    </Text>
                </Center>
                <SubscriptionsOverview onSuccessUrlFallback={url} />
            </Container>
        </Flex>
    );
};

export default NonValidSubscriptionPage;
