import React from 'react';
import { InputProps, Input } from '@chakra-ui/react';

interface IDSMTriangleInputProps extends InputProps {}

const DSMTriangleInput: React.FC<IDSMTriangleInputProps> = ({ ...rest }) => {
    return (
        <Input
            _placeholder={{
                color: 'white',
            }}
            _focus={{
                color: 'white',
            }}
            placeholder="0.0"
            borderRadius="0px"
            borderWidth="0px"
            height="2em"
            px="0.5ch"
            width="6ch"
            borderBottomWidth="2px"
            borderBottomColor="primary"
            color="white"
            textAlign="center"
            {...rest}
        />
    );
};

export default DSMTriangleInput;
