import { Box, Center, Container, Flex, Text, Heading, Alert, AlertIcon, AlertDescription } from '@chakra-ui/react';
import React from 'react';
import { useCurrentUserData } from '../../context/AuthProvider';
import { planDateToString } from '../../utils/plans';
import SubscriptionsOverview from '../organisms/SubscriptionsOverview';

const PlansPage: React.FC = () => {
    const data = useCurrentUserData();

    const validUntilString = data.type == 'active' ? planDateToString(data.validUntil) : '';

    return (
        <Flex direction="column">
            <Container maxW="1024px">
                <Center minH="200px" py="4" flexDir="column">
                    <Heading size="2xl">Velg tilgang</Heading>
                    <Text pt="2" maxW={['100%', '70%']} textAlign="center" fontSize="18px">
                        {'Her kan du kjøpe tilgang til tjenesten. Månedsprisen er billigere dersom du velger en lengere ' +
                            'tilgang. Det vil være et engangskjøp, og vil ikke automatisk fornyes når tilgangen går ut.'}
                    </Text>
                </Center>
                {data.validSubscription && (
                    <Center mb="4">
                        <Alert
                            status="info"
                            colorScheme="orange"
                            maxW={['100%', '70%']}
                            color="black"
                            borderRadius="lg"
                            fontSize="sm"
                        >
                            <AlertIcon />
                            <AlertDescription>
                                Du har allerede tilgang til alle regnesett til
                                <Box as="span" fontSize="md" ml="1" fontWeight="600">
                                    {validUntilString}
                                </Box>
                                .
                                <br /> Tilgang du eventuell kjøper vil bli lagt til på din eksisterende tilgang.
                            </AlertDescription>
                        </Alert>
                    </Center>
                )}
                <SubscriptionsOverview />
            </Container>
        </Flex>
    );
};

export default PlansPage;
