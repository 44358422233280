import { Flex } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import useConfetti from '../../hooks/useConfetti';
import FinishedSetButtonRow from '../molecules/FinishedSetButtonRow';
import FinishedSetWithHeader from '../molecules/FinishedSetWithHeader';

interface IFinishedSetProps {
    fullScore: boolean;
    header: string;
}

export const FinishedSet: React.FC<IFinishedSetProps> = ({ fullScore, header }) => {
    const { fireworks } = useConfetti();

    useEffect(() => {
        if (fullScore) {
            fireworks();
        }
    }, [fullScore]);

    return (
        <Flex direction="column" justifyContent="center" alignItems="center">
            <FinishedSetWithHeader fullScore={fullScore} title={header} />
            <FinishedSetButtonRow fullScore={fullScore} />
        </Flex>
    );
};

export default FinishedSet;
