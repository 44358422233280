import { Flex } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import Button from '../atoms/Button';

interface IFinishedSetButtonRowProps {
    fullScore: boolean;
}

export const FinishedSetButtonRow: React.FC<IFinishedSetButtonRowProps> = ({ fullScore }) => {
    const history = useHistory();
    const { topic, exerciseKey } = useParams<{ topic: string; exerciseKey: string }>();

    const onMoreSetsPressed = () => {
        history.push('/temaer');
    };

    const onTryAgainPressed = () => {
        history.replace(`/temaer/${topic}/${exerciseKey}`);
        window.location.reload();
    };

    const onNextSetPressed = () => {
        const name = exerciseKey.split('_');
        const exerciseNr = parseInt(name?.[1] ?? '0');
        if (exerciseNr >= 19) history.push(`/`);
        else {
            const newExercise = `${name?.[0]}_${exerciseNr + 1}`;
            history.replace(`/temaer/${topic}/${newExercise}`);
            window.location.reload();
        }
    };

    if (fullScore) {
        return (
            <Flex direction="row" justifyContent="center">
                <Button
                    variant="secondary"
                    mt="14px"
                    label="Utforsk flere oppgavesett"
                    color="black"
                    onClick={onMoreSetsPressed}
                />
                <Button variant="primary" label="Neste sett" color="black" onClick={onNextSetPressed} />
            </Flex>
        );
    }

    return (
        <Flex direction="row" justifyContent="center" mt="14px">
            <Button variant="secondary" label="Start på nytt" color="black" onClick={onTryAgainPressed} mr="2" />
            <Button variant="primary" label="Flere oppgavesett" color="white" onClick={onMoreSetsPressed} mr="2" />
            <Button variant="secondary" label="Neste sett" color="black" onClick={onNextSetPressed} />
        </Flex>
    );
};

export default FinishedSetButtonRow;
