import { Box, Heading, Table, Tbody, Text, Td, Th, Thead, Tr, VStack, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import CustomColors from '../../theme/CustomColors';
import HeroHeader from './HeroHeader';

interface OmregningVektRulesProps {
    isMobile: boolean;
}

const OmregningVektTable: React.ElementType<OmregningVektRulesProps> = ({ isMobile }) => (
    <>
        <HeroHeader text="Omregning vekt" fontSize={['32', '36']} fontWeight="bold" fontFamily="lato" pt="5" />
        <VStack pt={5}>
            <Table variant="simple" size={isMobile ? 'sm' : 'md'}>
                <Thead>
                    <Tr>
                        <Th textColor="white" fontSize="l">
                            FRA
                        </Th>
                        <Th textColor="white" fontSize="l">
                            TIL
                        </Th>
                        <Th textColor="white" fontSize="l">
                            METODE
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td>kilogram (Kg)</Td>
                        <Td>gram (g)</Td>
                        <Td>Gange med 1000 (1kg x 1000 = 1000gram)</Td>
                    </Tr>
                    <Tr>
                        <Td>gram (g)</Td>
                        <Td>milligram (mg)</Td>
                        <Td>Gange med 1000 (1g x 1000 = 1000mg)</Td>
                    </Tr>
                    <Tr>
                        <Td>milligram (mg)</Td>
                        <Td>mikrogram (μg)</Td>
                        <Td>Gange med 1000 (1mg x 1000 = 1000μg)</Td>
                    </Tr>
                    <Tr>
                        <Td>mikrogram (μg)</Td>
                        <Td>milligram (mg)</Td>
                        <Td>Dele med 1000 (1000μg / 1000 = 1mg)</Td>
                    </Tr>
                    <Tr>
                        <Td>milligram (mg)</Td>
                        <Td>Gram (g)</Td>
                        <Td>Dele med 1000 (1000mg / 1000 = 1g)</Td>
                    </Tr>
                    <Tr>
                        <Td>Gram (g)</Td>
                        <Td>kilogram (Kg)</Td>
                        <Td>Dele med 1000 (1000g / 1000 = 1μg)</Td>
                    </Tr>
                </Tbody>
            </Table>
            <SimpleGrid columns={[1, 1, 2, 2]} gap={10} justifyItems="center" pt={5}>
                <Box
                    borderRadius="1rem"
                    w="fit-content"
                    border="1px"
                    bg={CustomColors.primaryLight}
                    borderColor="white"
                    padding="20px"
                >
                    <Heading textShadow="lg" size="md" textAlign="left" fontWeight="600" fontStyle="normal" mt="20px">
                        {'Tips til omgjøring til mindre enhet'}
                    </Heading>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'Når man skal gjøre om til en enhet som er mindre enn den man har, kan man ' +
                            'legge til tilsvarende nuller i det man ganger med bak den verdien man skal gjøre om fra.'}
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'For eksempel: hvis man skal regne 1kg om til gram så skal man gange med 1000. I 1000 ' +
                            'er det 3 nuller, da kan man legge til 3 nuller til 1 og får 1000gram.'}
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'Men hvis man starter med et tall som har et komma, må man begynne med å flytte komma mot høyre ' +
                            ' tilsvarende antall nuller i det man skal gange med. Er det ikke flere tall etter komma, ' +
                            'kan man begynne å legge til nuller.'}
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'Et eksempel på dette er hvis man skal gjøre om 0.5kg til gram. Da skal man gange tallet med 1000 ' +
                            'som har 3 nuller. Da begynner man med å flytte kommaet i 0.5 en plass til høyre som blir 5. ' +
                            'Man har da to nuller igjen fra det man skulle gange med, men ingen tall bak komma. ' +
                            'Da legger man rett og slett bare til to nuller som i eksempelet over og vi får svaret 500g.'}
                    </Text>
                </Box>
                <Box
                    borderRadius="1rem"
                    w="fit-content"
                    border="1px"
                    bg={CustomColors.primaryLight}
                    borderColor="white"
                    padding="20px"
                >
                    <Heading textShadow="lg" size="md" textAlign="left" fontWeight="600" fontStyle="normal" mt="20px">
                        {'Tips til omgjøring til større enhet'}
                    </Heading>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'Når man skal gjøre om til en enhet som er større enn den man har, kan man flytte komma ' +
                            'tilsvarende antall nuller i det man deler med.'}
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'For eksempel: hvis man skal regne 1000mg om til gram så skal man dele med 1000. ' +
                            'I 1000 er det 3 nuller, da kan man flytte komma 3 plasser til venstre og ' +
                            '1000mg blir da 1,000g eller 1gram.'}
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'I tilfellet hvor komma kan bli flyttet til venstre slik at det ikke er flere tall, ' +
                            'må man begynne med å legge til nuller fra venstre.'}
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'For eksempel: hvis man skal regne 10mg om til gram, så skal man dele med 1000. ' +
                            'I 1000 er det 3 nuller, da kan man flytte komma 3 plasser til venstre. ' +
                            'Når man har flyttet komma en plass til venstre får man 1,0, men neste gang man flytter kommaet' +
                            ' må man legge til en null fra venstre for at tallet skal bli gyldig. Det er da 2 nuller igjen ' +
                            'og går fra 1,0 til 0,1 til 0,01gram.'}
                    </Text>
                </Box>
            </SimpleGrid>
        </VStack>
    </>
);

export default OmregningVektTable;
