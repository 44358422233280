const CustomStyles = {
    global: {
        body: {
            bg: 'primary',
            color: 'white',
            fontFamily: 'Lato',
            height: '100%',
            width: '100%',
        },
        html: {
            height: '100%',
            width: '100%',
        },
        root: {
            height: '100%',
            width: '100%',
        },
    },
};

export default CustomStyles;
