import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import Footer from './components/molecules/Footer';
import PageHeader from './components/organisms/PageHeader';
import TestPage from './components/pages/ComponentTestPage';
import ExerciseSets from './components/pages/ExerciseSets';
import LandingPage from './components/pages/LandingPage';
import CalculationRules from './components/pages/CalculationRules';
import PlansPage from './components/pages/PlansPage';
import ActiveSubscriptionProtectedPage from './utils/ActiveSubscriptionProtectedRoute';
import FailedPaymentPage from './components/pages/FailedPayment';
import ContactUs from './components/pages/ContactUs';
import UniversityPromoPage from './components/pages/UniversityPromoPage';
import TopicOverview from './components/pages/TopicOverview';
import PrivacyPage from './components/pages/PrivacyPage';
import AboutUs from './components/pages/AboutUs';
import FeedbackForm from './components/molecules/FeedbackForm';
import { ResetPasswordPage } from './components/pages/ResetPassword';

const App: React.FC = () => {
    return (
        <>
            <FeedbackForm />
            <Box minH="100%" display="flex" flexDirection="column" alignItems="stretch">
                <PageHeader />
                <Box flexGrow={1} flexShrink={0}>
                    <Switch>
                        <Route path="/" component={LandingPage} exact />
                        <Route path="/temaer" component={TopicOverview} exact />
                        <Route path="/temaer/:topic" component={ExerciseSets} exact />
                        <ActiveSubscriptionProtectedPage
                            path="/temaer/:topic/:exerciseKey"
                            component={TestPage}
                            exact
                        />
                        <ActiveSubscriptionProtectedPage path="/regneregler" component={CalculationRules} exact />
                        <Route path="/kjop-tilgang" component={PlansPage} exact />
                        <Route path="/kjop-mislykkes" component={FailedPaymentPage} exact />
                        <Route path="/universitetsavtaler" component={UniversityPromoPage} exact />
                        <Route path="/kontakt-oss" component={ContactUs} exact />
                        <Route path="/personvern" component={PrivacyPage} exact />
                        <Route path="/om-oss" component={AboutUs} exact />
                        <Route path="/nullstill-passord" component={ResetPasswordPage} exact />
                    </Switch>
                </Box>
                <Footer flexShrink={0} />
            </Box>
        </>
    );
};

export default App;
