import React from 'react';
import { AspectRatio, Box, Container, SimpleGrid, useBreakpointValue } from '@chakra-ui/react';
import { ReactComponent as Illustration } from '../../static/Illustrations/select.svg';
import LandingPageBox from '../atoms/LandingPageBox';
import PageHero from '../molecules/PageHero';
import { useHistory } from 'react-router-dom';
import { useExerciseTopics } from '../../hooks/swr';
import TopicPreFetch from '../../utils/TopicPreFetch';

const TopicOverview: React.FC = () => {
    const history = useHistory();

    const boxSize = useBreakpointValue({
        base: '300px',
    });

    const topics = useExerciseTopics();

    return (
        <Container maxW="1024px">
            <PageHero heading="Oppgave temaer" reverse>
                <AspectRatio w={boxSize} ratio={16 / 9}>
                    <Illustration style={{ objectFit: 'contain' }} />
                </AspectRatio>
            </PageHero>
            <SimpleGrid columns={[1, 3, 3, 4, 4]} spacingY="8" spacingX="8">
                {topics.map((item) => (
                    <Box key={item.url} display="flex" flex="1">
                        <LandingPageBox
                            key={item.title}
                            title={item.title}
                            description={item.description}
                            secondlineDescription="20 oppgavesett"
                            onClick={() => history.push('/temaer/' + item.url.replace('-sets', ''))}
                        />
                        <TopicPreFetch topic={item} key={item.url + '-prefetch'} />
                    </Box>
                ))}
            </SimpleGrid>
        </Container>
    );
};

export default TopicOverview;
