import { useState } from 'react';

const useCalculator = (): [string[], (result: string) => void] => {
    const [history, setHistory] = useState<string[]>([]);

    const onCalculate = (calculation: string) => {
        setHistory([...history, calculation]);
    };
    return [history, onCalculate];
};

export default useCalculator;
