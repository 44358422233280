import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Tabs, TabList, Tab, TabPanels, Text, TabPanel, useBoolean, Heading, VStack, Box } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import BasicButton from '../components/atoms/Button';
import { useIsSignedIn } from '../context/AuthProvider';
import useConfetti from '../hooks/useConfetti';
import ApplyForPromoForm from './ApplyForPromoForm';
import UsePromoForm from './UsePromoForm';

interface IPromoCodeTabsProps {
    onSucess?: () => void;
}

const PromoCodeTabs: React.FC<IPromoCodeTabsProps> = ({}) => {
    const [isSuccess, { on }] = useBoolean();
    const { fireworks } = useConfetti();
    const history = useHistory();
    const isSignedIn = useIsSignedIn();
    const onSuccess = () => {
        on();
        fireworks();
    };

    const onNavigateClick = () => history.push('/oppgavesett');

    if (isSuccess) {
        return (
            <VStack spacing="8">
                <Heading>Hurra, nå har du tilgang!</Heading>
                <BasicButton
                    onClick={onNavigateClick}
                    variant="secondary"
                    label="Utforsk oppgavesett"
                    rightIcon={<ArrowForwardIcon />}
                />
            </VStack>
        );
    }

    if (!isSignedIn) {
        return (
            <VStack w="100%">
                <Heading size="lg" pb="3">
                    Søk om fri tilgang
                </Heading>
                <Text>
                    For å søke om tilgang må du være{' '}
                    <Box as="span" fontWeight="bold">
                        logget inn.
                    </Box>
                </Text>
            </VStack>
        );
    }

    return (
        <VStack w="100%">
            <Heading size="lg" pb="3">
                Søk om fri tilgang
            </Heading>
            <Tabs
                defaultIndex={0}
                variant="unstyled"
                minW={['100%', '80%', '50%', '40%']}
                maxW={['100%', '80%', '50%', '40%']}
            >
                <TabList justifyContent="center" color="gray.100" w="100%">
                    <Tab
                        flex="1"
                        mr="1"
                        borderBottomWidth="2px"
                        fontSize={['sm', 'md']}
                        _selected={{ color: 'white', fontWeight: 'bold', borderColor: 'white' }}
                    >
                        Søk om tilganskode
                    </Tab>
                    <Tab
                        flex="1"
                        ml="1"
                        fontSize={['sm', 'md']}
                        borderBottomWidth="2px"
                        _selected={{ color: 'white', fontWeight: 'bold', borderColor: 'white' }}
                    >
                        Har tilgangskode
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel px="0">
                        <ApplyForPromoForm />
                    </TabPanel>
                    <TabPanel px="0">
                        <UsePromoForm onSuccess={onSuccess} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </VStack>
    );
};

export default PromoCodeTabs;
