import React from 'react';
import { Button } from '@chakra-ui/react';

interface MenuItemProps {
    selected: boolean;
    label: string;
    id: string;
    onClick: (id: string) => void;
}

const MenuItem: React.FC<MenuItemProps> = ({ selected, onClick, label, id }) => {
    const onButtonPress = () => {
        onClick(id);
    };

    const bgColor = selected ? 'white' : 'primary';
    const textColor = selected ? 'black' : 'white';

    return (
        <Button
            borderRadius="12px"
            fontFamily="Lato"
            fontSize={['sm', 'sm', 'sm', 'md']}
            fontWeight="normal"
            lineHeight={['18px', '21px']}
            bg={bgColor}
            h={'2.2rem'}
            py={['4px', '4px', '4px', '8px']}
            px={['8px', '8px', '8px', '16px']}
            color={textColor}
            onClick={onButtonPress}
            _hover={{ color: 'black' }}
        >
            {label}
        </Button>
    );
};

export default MenuItem;
