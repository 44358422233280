import { Alert, BoxProps } from '@chakra-ui/react';
import React from 'react';
import { CloudErrorCodes, CLOUD_ERROR_MESSAGES } from '../../types/CloudFunctions';

interface IPromoCodeFeedbackProps extends BoxProps {
    status?: CloudErrorCodes | 'success';
    successMessage: string;
}

const PromoCodeFeedback: React.FC<IPromoCodeFeedbackProps> = ({ successMessage, status, ...rest }) => {
    if (!status) {
        return null;
    }

    if (status == 'success') {
        return (
            <Alert
                hidden={status == undefined}
                status="success"
                borderRadius="md"
                color="black"
                fontWeight="bold"
                {...rest}
            >
                {successMessage}
            </Alert>
        );
    }

    const _success: CloudErrorCodes = status as CloudErrorCodes;

    return (
        <Alert hidden={status == undefined} status="error" bg="red.500" borderRadius="md">
            {CLOUD_ERROR_MESSAGES[_success]}
        </Alert>
    );
};

export default PromoCodeFeedback;
