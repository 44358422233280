import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Flex, Heading, Text, HStack, VStack } from '@chakra-ui/react';
import { useIsMobile } from '../../hooks/useIsMobile';
import React from 'react';

interface IExerciseCardProps {
    title: string;
    description: string;
    secondlineDescription?: string;
    onClick?: () => void;
    label?: string;
    solvedLastTime?: number;
    tries?: number;
}

export const ExerciseCard: React.FC<IExerciseCardProps> = ({
    title,
    secondlineDescription,
    onClick,
    tries,
    solvedLastTime,
}) => {
    const isMobile = useIsMobile();
    return (
        <Flex
            w="100%"
            padding="20px"
            bg="#FDF9F3"
            textColor="black"
            borderRadius="1rem"
            onClick={onClick}
            boxShadow="0 0 6px grey"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            transitionDuration="0.2s"
            mx="0"
            _hover={{
                transform: ['none', 'none', ' none', 'none', 'scale(1.025)'],
                cursor: 'pointer',
            }}
        >
            <Flex flex="1">
                <VStack align="flex-start" spacing="0">
                    <Heading
                        textShadow="lg"
                        size={isMobile ? 'sm' : 'md'}
                        textAlign="left"
                        fontWeight="600"
                        fontStyle="normal"
                    >
                        {title}
                    </Heading>
                    {secondlineDescription != undefined && (
                        <Text fontSize="12px" color="#333333" my="10px">
                            {secondlineDescription}
                        </Text>
                    )}
                </VStack>
            </Flex>
            <Flex justify="flex-end" flex="1">
                {!isMobile && (
                    <VStack spacing="0" align="flex-start" mr="10px">
                        <HStack align="flex-end" spacing="1">
                            <Heading fontWeight="bold" fontSize="16px">
                                {tries}
                            </Heading>
                            <Text fontSize="12px" fontWeight="600">
                                {tries === 1 ? 'gjennomføring' : 'gjennomføringer'}
                            </Text>
                        </HStack>
                        <Heading fontSize="12px" color="rgba(0,0,0,0.6)">
                            På dette oppgavesettet
                        </Heading>
                    </VStack>
                )}
                <VStack spacing="0" align="flex-start" mr="10px">
                    <HStack align="flex-end" spacing="2">
                        <Heading fontWeight="bold" fontSize="16px">
                            {solvedLastTime}/15
                        </Heading>
                        <Text fontSize="12px" fontWeight="600">
                            rette
                        </Text>
                    </HStack>
                    <Heading fontSize="12px" color="rgba(0,0,0,0.6)">
                        Forrige resultat
                    </Heading>
                </VStack>
                <ArrowForwardIcon boxSize="6" my="auto"></ArrowForwardIcon>
            </Flex>
        </Flex>
    );
};

export default ExerciseCard;
