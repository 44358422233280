import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Heading,
    HStack,
    Spinner,
    VStack,
    Text,
    Grid,
    GridItem,
    Divider,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useExerciseSetProgress from '../../hooks/useExerciseSetProgress';
import CustomColors from '../../theme/CustomColors';
import AnswerRow from '../molecules/AnswerRow';
import Calculator from './Calculator';
import { ReactComponent as CalculatorIcon } from '../../static/icons/calculator.svg';
import TaskNumber from '../atoms/TaskNumber';
import FinishedSet from './FinishedSet';
import { exerciseToAnswerString } from '../../utils/exercise';
import { useExerciseSetInTopic } from '../../hooks/swr';

interface IMobileTaskViewProps {}

export const MobileTaskView: React.FC<IMobileTaskViewProps> = ({}) => {
    const { topic, exerciseKey } = useParams<{ topic: string; exerciseKey: string }>();
    const exerciseSet = useExerciseSetInTopic(exerciseKey, topic);

    const { currentExercise, validate, progress, onExerciseClick, state, onNext, onPrev } = useExerciseSetProgress(
        exerciseSet?.exercises ?? [],
        exerciseSet?.id ?? -1,
        topic,
    );
    const [displayCalc, setDisplayCalc] = useState(false);

    const toggleDisplayCalc = () => {
        setDisplayCalc(!displayCalc);
    };

    if (!exerciseSet || !currentExercise) {
        return <Spinner />;
    }

    const correctAnswers = progress.filter((item) => item.state == 'correct').length;

    return (
        <VStack>
            <Accordion allowToggle w="100%">
                <AccordionItem borderStyle="none">
                    <h2>
                        <AccordionButton bg={CustomColors.primaryLight} _expanded={{ bg: CustomColors.primary }}>
                            <Box flex="1" textAlign="left">
                                <Heading
                                    fontSize="md"
                                    color="white"
                                    textAlign="left"
                                    alignSelf="left"
                                    fontWeight="extrabold"
                                >
                                    {exerciseSet.title}
                                </Heading>
                                <h2>{`Oppgave ${currentExercise.exercise.id + 1}`}</h2>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel bg={CustomColors.primaryLight} paddingLeft="2px">
                        <Grid
                            templateColumns="repeat(3, 1fr)"
                            gap={1}
                            column={2}
                            spacing={['4px', '8px']}
                            py={['4px', '10px']}
                            px={['8px', '16px']}
                            bg="primaryLight"
                            borderRadius="8"
                            width="fit-content"
                            align="flex-start"
                            height="100%"
                        >
                            {Object.keys(progress).map((key, i) => (
                                <GridItem key={key}>
                                    <TaskNumber
                                        key={key}
                                        taskName={`Oppgave ${i + 1}`}
                                        taskStatus={progress[i].state}
                                        currentExcercise={progress[i].exercise.id == currentExercise.exercise.id}
                                        onClick={() => onExerciseClick(i)}
                                    />
                                </GridItem>
                            ))}
                        </Grid>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            {state === 'finished' ? (
                <FinishedSet
                    fullScore={correctAnswers == progress.length}
                    header={`${correctAnswers} av ${progress.length} riktige`}
                />
            ) : (
                <>
                    <Box width="100%" height="100%" pt="5" pb="10">
                        <Text fontSize="20px" fontWeight="semibold" fontFamily="Lato" textAlign="left">
                            {currentExercise.exercise.description}
                        </Text>
                    </Box>
                    <VStack spacing={1}>
                        {currentExercise.state === 'wrong' && (
                            <Text>Riktig svar: {exerciseToAnswerString(currentExercise.exercise)}</Text>
                        )}
                        {currentExercise.state === 'missingUnit' && <Text>Husk benevning!</Text>}
                        <AnswerRow
                            onClick={validate}
                            animate={state == 'success'}
                            value={currentExercise.value}
                            onNext={onNext}
                            onPrev={onPrev}
                            exerciseState={currentExercise.state}
                        />
                    </VStack>
                    <HStack alignItems="center" w="100%" pt="36px">
                        <Box h="50px">
                            <CalculatorIcon width="40px" height="80px" onClick={toggleDisplayCalc} />
                            {displayCalc ? <Divider /> : ''}
                        </Box>
                    </HStack>
                    {displayCalc ? <Calculator unitType="s" /> : ''}
                </>
            )}
        </VStack>
    );
};

export default MobileTaskView;
