import React from 'react';
import { useExerciseSetInTopic } from '../hooks/swr';

interface IExerciseSetPreFetchProps {
    topic: string;
    set: string;
}

const ExerciseSetPreFetch: React.FC<IExerciseSetPreFetchProps> = ({ set, topic }) => {
    // Call on exercise hook which will prefetch and store exercise set using
    // swr
    useExerciseSetInTopic(set, topic);

    return null;
};

export default ExerciseSetPreFetch;
