import { ExerciseSet, ExerciseSetOption, ExerciseTopic } from '../types/Exercises';

const CLOUD_BASE_URL = process.env.REACT_APP_CLOUD_STORAGE_URL;
export const getExerciseTopics = async (): Promise<ExerciseTopic[]> => {
    const res = await fetch(CLOUD_BASE_URL + 'index.json');
    const data = await res.json();

    return data as ExerciseTopic[];
};

export const getExerciseSets = async (_: string, topic: string): Promise<ExerciseSetOption[]> => {
    const res = await fetch(CLOUD_BASE_URL + `${topic.toLowerCase()}/index.json`);
    const data = await res.json();

    return data as ExerciseSetOption[];
};
export const getExerciseSet = async (_: string, topic: string, setName: string): Promise<ExerciseSet> => {
    const res = await fetch(CLOUD_BASE_URL + `${topic.toLowerCase()}/${setName}.json`);
    const data = await res.json();

    return data as ExerciseSet;
};
