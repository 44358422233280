import React from 'react';
import { VStack, Heading, Text, Icon, Button, Stack, Link } from '@chakra-ui/react';
import { MdEmail, MdDateRange, MdExitToApp, MdPublic } from 'react-icons/md';
import InformationWithLeadingText from '../atoms/InformationWithLeadingText';
import { useCurrentUser } from '../../context/AuthProvider';
import firbeaseInstance from '../../utils/firebase';
import SubscriptionInformation from '../atoms/SubscriptionInformation';

interface IAccountInformationProps {
    // An optional callback to call if a item is pressed in the
    // information
    onItemClick?: () => void;
}

const AccountInformation: React.FC<IAccountInformationProps> = ({ onItemClick }) => {
    const currentUser = useCurrentUser();

    if (!currentUser) {
        return <Text>Ikke logget inn</Text>;
    }

    const getSignInMethod = () => {
        switch (currentUser.providerData[0]?.providerId) {
            case 'google.com':
                return 'Google';
            case 'password':
                return 'Brukernavn/Passord';
            default:
                return 'Ukjent';
        }
    };

    const onLogout = () => firbeaseInstance.auth().signOut();

    return (
        <VStack align="flex-start" flexGrow={1} spacing="4">
            <Heading size="md">Profilinformasjon</Heading>
            <Stack flexDir={['column', 'row']} align="flex-start" justify="space-between" w="100%" spacing={['4', '0']}>
                <InformationWithLeadingText heading="Epostaddresse" icon={<Icon as={MdEmail} />}>
                    <Text>{currentUser.email}</Text>
                </InformationWithLeadingText>
                <InformationWithLeadingText heading="Tilgang" icon={<Icon as={MdDateRange} />}>
                    <VStack minH={['auto', 'auto', 'auto', '150px']} justify="space-between" align="flex-start">
                        <SubscriptionInformation onButtonClick={onItemClick} />
                        <VStack align="flex-start">
                            <Text fontWeight="bold">Student?</Text>
                            <Link href="/#/universitetsavtaler" onClick={onItemClick}>
                                Sjekk om ditt universitet har avtale med oss!
                            </Link>
                        </VStack>
                    </VStack>
                </InformationWithLeadingText>
                <InformationWithLeadingText heading="Logget inn via" icon={<Icon as={MdPublic} />}>
                    <VStack minH={['auto', 'auto', 'auto', '150px']} justify="space-between" align="flex-start">
                        <Text>{getSignInMethod()}</Text>
                        <Button
                            rightIcon={<Icon as={MdExitToApp} />}
                            variant="ghost"
                            p="0"
                            _hover={{ color: '#9c0000' }}
                            color="white"
                            onClick={onLogout}
                        >
                            Logg ut
                        </Button>
                    </VStack>
                </InformationWithLeadingText>
            </Stack>
        </VStack>
    );
};

export default AccountInformation;
