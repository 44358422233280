import { useState, useEffect, useRef, useMemo } from 'react';

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
};

const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
};

const useOrigin = <T extends HTMLElement>(): [React.MutableRefObject<T | null>, number, number] => {
    const { width, height } = useWindowDimensions();
    const ref = useRef<T | null>(null);

    const originX = useMemo(() => {
        if (ref.current) {
            return (ref.current.offsetLeft + ref.current.scrollWidth * 0.5) / width;
        }

        return 0;
    }, [width, ref.current]);

    const originY = useMemo(() => {
        if (ref.current) {
            return (ref.current.offsetTop + ref.current.scrollHeight) / height;
        }

        return 0;
    }, [height, ref.current]);

    return [ref, originX, originY];
};

export default useOrigin;
