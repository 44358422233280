import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { CloudFunctionResponse } from '../types/CloudFunctions';
import { ExerciseSetWeekProgress } from '../types/Exercises';
import { SubscriptionPlan } from '../types/Plans';
import firbeaseInstance from './firebase';
import moment from 'moment';

export const createStripeCheckout = (
    productCode: string,
    sucessUrlFallback?: string,
): Promise<firebase.functions.HttpsCallableResult> =>
    firbeaseInstance.functions().httpsCallable('createStripeCheckout')({ productCode, success_url: sucessUrlFallback });

export const postFeedback = (title: string, description: string): Promise<CloudFunctionResponse> =>
    firbeaseInstance
        .functions()
        .httpsCallable('addFeedbackToTrello')({ title, description })
        .then((res) => res.data);

export const applyForPromoCode = (email: string): Promise<CloudFunctionResponse> =>
    firbeaseInstance
        .functions()
        .httpsCallable('getPromoCode')({ email })
        .then((res) => res.data);

export const usePromoCode = (promo: string): Promise<CloudFunctionResponse> =>
    firbeaseInstance
        .functions()
        .httpsCallable('usePromoCode')({ promo })
        .then((res) => res.data);

export const getAccessProducts = (): Promise<SubscriptionPlan[]> =>
    firbeaseInstance
        .firestore()
        .collection('static')
        .doc('stripe')
        .get()
        .then((data) => data.data()?.products ?? [])
        .then((products) => products as SubscriptionPlan[]);

export const getSolvedExerciseSetsPerWeek = (userId: string): Promise<ExerciseSetWeekProgress[]> =>
    firbeaseInstance
        .firestore()
        .collection('progress')
        .doc(userId)
        .collection('weeks')
        .get()
        .then((data) => data.docs.map((doc) => ({ key: doc.id, nSolved: doc.get('nSolved') ?? 0 })));

export const incrementSetFinished = (userId: string): void => {
    const date = moment();
    const key = date.year() + '-' + date.isoWeek();

    firbeaseInstance
        .firestore()
        .collection('progress')
        .doc(userId)
        .collection('weeks')
        .doc(key)
        .set({ nSolved: firebase.firestore.FieldValue.increment(1) }, { merge: true });
};

export const updateOverallProgress = (
    userId: string,
    exerciseType: string,
    id: number,
    correctAnswers: number,
): void => {
    firbeaseInstance
        .firestore()
        .collection('progress')
        .doc(userId)
        .collection('overall')
        .doc(exerciseType)
        .set(
            { [id]: { tries: firebase.firestore.FieldValue.increment(1), nCorrectAnswers: correctAnswers } },
            { merge: true },
        );
};

export const getOverallProgress = (userId: string) =>
    firbeaseInstance
        .firestore()
        .collection('progress')
        .doc(userId)
        .collection('overall')
        .get()
        .then((data) => data.docs.reduce((acc, doc) => ({ ...acc, [doc.id]: doc.data() }), {}));
