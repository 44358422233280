import React from 'react';
import { HStack, VStack } from '@chakra-ui/react';
import BasicInput from '../components/atoms/Input';
import { Form, FormikErrors, FormikProps, withFormik } from 'formik';
import { emailValidator } from '../utils/validators';
import BasicButton from '../components/atoms/Button';
import { applyForPromoCode } from '../utils/firebaseFunctions';
import PromoCodeFeedback from '../components/atoms/PromoCodeFeedback';

interface IApplyForPromoValues {
    email: string;
}

interface IApplyForPromoProps {}

const InnerApplyForPromo: React.FC<FormikProps<IApplyForPromoValues>> = ({
    handleBlur,
    handleChange,
    errors,
    touched,
    isValid,
    isSubmitting,
    status,
}) => (
    <VStack>
        <Form style={{ width: '100%' }} autoComplete="off">
            <HStack align="stretch" spacing={4}>
                <BasicInput
                    id="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="f.eks ola@nordmann.no"
                    flex="1"
                    isDisabled={isSubmitting}
                    error={errors.email}
                    formControl={{ isInvalid: touched.email && !!errors.email }}
                />
                <BasicButton
                    isFullWidth
                    disabled={!isValid || isSubmitting}
                    isLoading={isSubmitting}
                    loadingText="Laster"
                    colorScheme="teal"
                    type="submit"
                    variant="secondary"
                    label="Søk"
                    maxW="30%"
                />
            </HStack>
        </Form>
        <PromoCodeFeedback status={status} successMessage="En tilgangskode er sendt til din epost!" />
    </VStack>
);

const ApplyForPromoForm = withFormik<IApplyForPromoProps, IApplyForPromoValues>({
    validate: ({ email }) => {
        const errors: FormikErrors<IApplyForPromoValues> = {};

        errors.email = emailValidator(email);
        const hasErrors = Object.values(errors).some((value) => value !== undefined);

        return hasErrors ? errors : {};
    },
    handleSubmit: async ({ email }, { setSubmitting, setStatus }) => {
        setSubmitting(true);
        const res = await applyForPromoCode(email);

        if (res.type == 'success') {
            setStatus('success');
        } else {
            setStatus(res.code);
        }

        setSubmitting(false);
    },
    validateOnMount: true,
})(InnerApplyForPromo);

export default ApplyForPromoForm;
