import { Flex, Container } from '@chakra-ui/react';
import { Box, SimpleGrid, Text, Breadcrumb, BreadcrumbLink, BreadcrumbItem } from '@chakra-ui/react';
import React, { useState } from 'react';
import DoseStyrkeMengdeRules from '../atoms/DoseStyrkeMengdeRules';
import InfusjonshastighetRules from '../atoms/InfusjonshastighetRules';
import OmregningTidTable from '../atoms/OmregningTidTable';
import OmregningVektTable from '../atoms/OmregningVektTable';
import OmregningVeskeTable from '../atoms/OmregningVeskeTable';
import CalcRulesSubHeader from '../molecules/CalcRulesSubHeader';
import HeroHeader from '../atoms/HeroHeader';
import { useIsMobile } from '../../hooks/useIsMobile';
import StyrkeProsentRules from '../atoms/StyrkeProsentRules';
import LandingPageBox from '../atoms/LandingPageBox';

type RuleTopics =
    | 'Omregning vekt'
    | 'Omregning væske'
    | 'Omregning tid'
    | 'Dose, styrke, mengde'
    | 'Infusjons- og injeksjonshastighet'
    | 'Styrke og prosent';
interface RuleTopicContent {
    title: RuleTopics;
    description: string;
}

const CalculationRules: React.FC = () => {
    const isMobile = useIsMobile();
    const [selectedTopic, setSelectedTopic] = useState<RuleTopics | undefined>();
    const ruleTopics: RuleTopicContent[] = [
        { title: 'Omregning vekt', description: 'Forklarer omregning mellom ulike vektenheter' },
        { title: 'Omregning væske', description: 'Forklarer omregning mellom ulike væskeenheter' },
        { title: 'Omregning tid', description: 'Forklarer omregning mellom ulike tidsenheter' },
        { title: 'Dose, styrke, mengde', description: 'Forklarer forholdene mellom dose, styrke og mengde' },
        {
            title: 'Infusjons- og injeksjonshastighet',
            description: 'Forklarer hvordan man kommer frem til infusjonshastighet',
        },
        { title: 'Styrke og prosent', description: 'Forklarer forholdene mellom styrke og prosent' },
    ];

    const onTopicClick = (topic: RuleTopicContent) => {
        window.scrollTo(0, 0);
        setSelectedTopic(topic.title);
    };

    const onBreadcumLinkClick = () => {
        setSelectedTopic(undefined);
        window.scrollTo(0, 0);
    };

    if (!selectedTopic) {
        return (
            <Flex direction="column">
                <Container maxW="1024px">
                    <CalcRulesSubHeader />
                    <Breadcrumb pt="1em">
                        <BreadcrumbItem>
                            <BreadcrumbLink>Regneregler</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <HeroHeader text="Temaer" fontSize={['32', '36']} fontWeight="bold" fontFamily="lato" pt="5" />
                    <SimpleGrid columns={[1, 1, 3, 4, 4]} spacing={5} pt={10} justifyItems="start" px="0">
                        {ruleTopics.map((topic, i) => (
                            <LandingPageBox
                                key={i}
                                title={topic.title}
                                description={topic.description}
                                label="Utforsk regneregler"
                                onClick={() => onTopicClick(topic)}
                            />
                        ))}
                    </SimpleGrid>
                </Container>
            </Flex>
        );
    }

    const renderTable = () => {
        switch (selectedTopic) {
            case 'Omregning vekt':
                return <OmregningVektTable isMobile={isMobile} />;
            case 'Omregning væske':
                return <OmregningVeskeTable isMobile={isMobile} />;
            case 'Omregning tid':
                return <OmregningTidTable isMobile={isMobile} />;
            case 'Infusjons- og injeksjonshastighet':
                return <InfusjonshastighetRules />;
            case 'Dose, styrke, mengde':
                return <DoseStyrkeMengdeRules isMobile={isMobile} />;
            case 'Styrke og prosent':
                return <StyrkeProsentRules />;
            default:
                return <Text>Regel ikke tigjengelig</Text>;
        }
    };

    return (
        <Flex direction="column">
            <Container maxW="1024px">
                <CalcRulesSubHeader />
                <Box>
                    <Breadcrumb pt="1em">
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={onBreadcumLinkClick}>Regneregler</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <BreadcrumbLink>{selectedTopic}</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    {renderTable()}
                </Box>
            </Container>
        </Flex>
    );
};

export default CalculationRules;
