import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
import { HashRouter as Router } from 'react-router-dom';
import { AuthProvider } from './context/AuthProvider';
import { StaticProvider } from './context/StaticProvider';
import ScrollToTop from './utils/ScrollToTop';
import { SWRConfig, SWRConfiguration } from 'swr';
import { swrOnError } from './hooks/swr';

const SWR_CONFIG: SWRConfiguration = {
    refreshInterval: 60 * 1000, // refresh content every minut
    dedupingInterval: 60 * 1000, // the time two calls must be after each other to trigger a reload
    onError: swrOnError,
};

ReactDOM.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <SWRConfig value={SWR_CONFIG}>
                <StaticProvider>
                    <AuthProvider>
                        <Router>
                            <ScrollToTop />
                            <App />
                        </Router>
                    </AuthProvider>
                </StaticProvider>
            </SWRConfig>
        </ChakraProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
