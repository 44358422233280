import React from 'react';
import { AspectRatio, useBreakpointValue } from '@chakra-ui/react';
import PageHero from './PageHero';
import { ReactComponent as Mathematics } from '../../static/Illustrations/mathematics.svg';

interface ILandingPageSubHeaderProps {}

export const CalcRulesSubHeader: React.FC<ILandingPageSubHeaderProps> = () => {
    const boxSize = useBreakpointValue({
        base: '250px',
        md: '250px',
        lg: '300px',
    });

    return (
        <PageHero heading="Vær forberedt til eksamen" subHeading="- ved å regne flere oppgavesett">
            <AspectRatio w={boxSize} ratio={16 / 9}>
                <Mathematics width="100px" style={{ objectFit: 'contain' }} />
            </AspectRatio>
        </PageHero>
    );
};

export default CalcRulesSubHeader;
