import { ComponentStyleConfig } from '@chakra-ui/react';

const CustomDividerStyle: ComponentStyleConfig = {
    sizes: {
        lg: {
            borderBottomWidth: '5px',
            my: '2',
        },
    },
};

export default CustomDividerStyle;
