import React, { useEffect, useState, useRef } from 'react';
import { HStack, VStack } from '@chakra-ui/react';
import useOrigin from '../../hooks/useOrigin';
import BasicButton from '../atoms/Button';
import useConfetti from '../../hooks/useConfetti';
import BasicInput from '../atoms/Input';
import { ExerciseState } from '../../hooks/useExerciseSetProgress';

interface IAnswerRowProps {
    onClick: (value: string) => void;
    exerciseState: ExerciseState;
    onNext: () => void;
    onPrev: () => void;
    animate?: boolean;
    value?: string;
}

export const AnswerRow: React.FC<IAnswerRowProps> = ({
    onClick,
    onNext,
    onPrev,
    exerciseState,
    animate = false,
    value = '',
}) => {
    const [ref, x, y] = useOrigin<HTMLButtonElement>();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const nextButton = useRef<HTMLButtonElement | null>(null);
    const { realistic } = useConfetti();

    const [n1, setN1] = useState(value);

    useEffect(() => {
        setN1(value);
    }, [value]);

    useEffect(() => {
        if (animate) {
            realistic(x, y);
        }
    }, [animate, x, y]);

    useEffect(() => {
        if (exerciseState != 'correct' && exerciseState != 'wrong') {
            inputRef.current?.focus();
        } else if (exerciseState == 'wrong') {
            nextButton.current?.focus();
        }
    }, [exerciseState]);

    const handleClick = () => {
        onClick(n1);
    };

    const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key == 'Enter') {
            handleClick();
        }
    };

    const _onPrev = () => {
        onPrev();
        inputRef.current?.focus();
    };

    const _onNext = () => {
        onNext();
        inputRef.current?.focus();
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setN1(e.target.value);
    };

    const disabled = exerciseState == 'correct' || exerciseState == 'wrong';
    const wrongAnswer = exerciseState == 'wrong';

    return (
        <VStack spacing="12px" w={['100%', '50%']}>
            <BasicInput
                autoFocus
                ref={inputRef}
                width="100%"
                placeholder="ditt svar"
                textAlign="center"
                onChange={onInputChange}
                minW="200px"
                value={n1}
                onKeyDown={onKeyPress}
                isDisabled={disabled}
                wrongAnswer={wrongAnswer}
            />
            <HStack>
                <BasicButton onClick={_onPrev} variant="primary" label="forrige" />
                <BasicButton
                    ref={ref}
                    minW="100px"
                    variant="secondary"
                    label="Svar"
                    onClick={handleClick}
                    disabled={disabled || !n1.length}
                />
                <BasicButton ref={nextButton} onClick={_onNext} variant="primary" label="neste" />
            </HStack>
        </VStack>
    );
};

export default AnswerRow;
