import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

export interface IMAButtonProps extends ButtonProps {
    variant: 'primary' | 'secondary';
    label: string;
}

const BasicButton = React.forwardRef<HTMLButtonElement, IMAButtonProps>(({ label, variant, ...rest }, ref) => (
    <Button
        ref={ref}
        fontSize={'14px'}
        variant="solid"
        _hover={{ bg: variant == 'primary' ? 'primaryLight' : 'secondaryLight' }}
        bg={variant == 'primary' ? 'primary' : 'whiteTwo'}
        color={variant == 'primary' ? 'white' : 'black'}
        px="16px"
        py="8px"
        borderRadius="12px"
        fontWeight="bold"
        {...rest}
    >
        {label}
    </Button>
));

BasicButton.displayName = 'BasicButton';

export default BasicButton;
