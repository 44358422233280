import { Button, ButtonProps } from '@chakra-ui/button';
import React from 'react';

interface IFeedbackButtonProps
    extends Omit<ButtonProps, 'borderRadius' | 'transform' | 'aria-label' | 'position' | 'right'> {}

const FeedbackButton: React.FC<IFeedbackButtonProps> = ({ ...props }) => {
    return (
        <Button
            bgColor="secondary"
            color="black"
            _hover={{
                bgColor: 'primaryLight',
                color: 'white',
            }}
            borderRadius="0"
            transform="rotate(-90deg)"
            aria-label="Gi tilbakemelding"
            position="fixed"
            height={['30px', '40px']}
            right={['-48px', '-54px']}
            lineHeight="32px"
            fontSize={['14px', '17px']}
            top="45%"
            zIndex="2"
            marginBottom="auto"
            marginTop="auto"
            {...props}
        >
            Tilbakemelding
        </Button>
    );
};

export default FeedbackButton;
