import { Container } from '@chakra-ui/react';
import React from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';
import MobileTaskView from '../organisms/MobileTaskView';
import TaskView from '../organisms/TaskView';

const TestPage: React.FC = () => {
    const isMobile = useIsMobile();

    return (
        <Container maxW="1024px" pt={['15px', '50px']}>
            {isMobile ? <MobileTaskView /> : <TaskView />}
        </Container>
    );
};

export default TestPage;
