import { Box, Heading, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import CustomColors from '../../theme/CustomColors';
import HeroHeader from './HeroHeader';

const InfusjonshastighetRules: React.FC = () => (
    <>
        <HeroHeader
            text="Infusjon- og injeksjonshastighet"
            fontSize={['32', '36']}
            fontWeight="bold"
            fontFamily="lato"
            pt="5"
        />
        <VStack pt={5}>
            <SimpleGrid columns={[1, 1, 2, 2]} gap={10} justifyItems="center" pt={5}>
                <Box
                    borderRadius="1rem"
                    w="fit-content"
                    border="1px"
                    bg={CustomColors.primaryLight}
                    borderColor="white"
                    padding="20px"
                >
                    <Heading textShadow="lg" size="md" textAlign="left" fontWeight="600" fontStyle="normal" mt="20px">
                        {'Finne infusjonshastigheten ml/time'}
                    </Heading>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'Milliliter per time (ml/timer) finner man ved å ta mengden ' +
                            'i milliliter og deler på antall timer den skal gis på.'}
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'Hvis man for eksempel har 100ml virkestoff som skal gis ' +
                            'iløpet av 4 timer deler man 100 på 4 som blir 25. Pasienten ' +
                            'skal da ha 25ml/time'}
                    </Text>
                </Box>
                <Box
                    borderRadius="1rem"
                    w="fit-content"
                    border="1px"
                    bg={CustomColors.primaryLight}
                    borderColor="white"
                    padding="20px"
                >
                    <Heading textShadow="lg" size="md" textAlign="left" fontWeight="600" fontStyle="normal" mt="20px">
                        {'Hvis tiden er mindre enn 1 time'}
                    </Heading>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'Når tiden er mindre enn 1 time kan man begynne med å regne ut ' +
                            'milliliter per minutt for så å gjøre dette om til timer. ' +
                            'Det gjøres ved å først dele mengden virkestoff på antall ' +
                            'minutter for å få ml/minutt. Deretter gange resultatet med ' +
                            '60 for å ml/time.'}
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'Hvis man har 100 ml virkestoff som skal gis på 20 minutter begynner' +
                            ' man med å dele 100ml på 20 som gir oss 5ml/min. 5ml/min ganges' +
                            ' så med 60 for å gjøre det om til timer og vi får 300ml/time.'}
                    </Text>
                </Box>
                <Box
                    borderRadius="1rem"
                    w="fit-content"
                    border="1px"
                    bg={CustomColors.primaryLight}
                    borderColor="white"
                    padding="20px"
                >
                    <Heading textShadow="lg" size="md" textAlign="left" fontWeight="600" fontStyle="normal" mt="20px">
                        {'Gjøre om fra ml/time til dråper/min'}
                    </Heading>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'Her antas det en dråpehastighet på 20 dråper/ml.'}
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'Hvis man har' +
                            ' en mengde i ml/time og trenger å vite hva det blir i dråper/min' +
                            ' vil man først dele på 60 for å få ml/min etterfulgt av å gange med ' +
                            '20 fordi dråpehastigheten er 20 dråper per ml og tallet vi har er antall ml.'}
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'Men man kan også kombinere de to regneoperasjonene i en. Dele på 60 og gange ' +
                            'med 20 kan gjøres om til en operasjon ved å ta 20 delt på 60 som blir 1/3.' +
                            'Så da kan man erstatte de to regneoperasjonene med kun å dele med 3.'}
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'Hvis vi for eksempel har 120 ml/time og skal gjøre det om til dråper/min ' +
                            'deler vi 120 på 3 og får 40 dråper/min.'}
                    </Text>
                </Box>
            </SimpleGrid>
        </VStack>
    </>
);

export default InfusjonshastighetRules;
