import React from 'react';
import { useExerciseTopics } from '../hooks/swr';

const TopicsPreFetch: React.FC = () => {
    // Call on exercise hook which will prefetch and store sets using
    // swr
    useExerciseTopics();

    return null;
};

export default TopicsPreFetch;
