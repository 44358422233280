import React from 'react';
import { Stack, Flex, Divider, Text, Alert, Link } from '@chakra-ui/react';
import { GoogleLoginButton } from 'react-social-login-buttons';
import firbeaseInstance, { isFirebaseError } from '../utils/firebase';
import BasicInput from '../components/atoms/Input';
import firebase from 'firebase/app';
import { Form, FormikErrors, FormikProps, withFormik } from 'formik';
import { emailValidator } from '../utils/validators';
import BasicButton from '../components/atoms/Button';

interface ISignInFormValues {
    email: string;
    password: string;
}

interface ISignInFormProps {}

const InnerSignInForm: React.FC<FormikProps<ISignInFormValues>> = ({
    handleBlur,
    handleChange,
    errors,
    touched,
    isValid,
    isSubmitting,
    status,
}) => (
    <Form>
        <Stack spacing={4}>
            {status == 'api-error' && (
                <Alert hidden={status != 'api-error'} status="error" bg="red.500">
                    En ukjent feil oppsto. Prøv igjen senere.
                </Alert>
            )}
            <BasicInput
                label="Epostadressen"
                id="email"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="f.eks ola@nordmann.no"
                error={errors.email}
                formControl={{ isInvalid: touched.email && !!errors.email }}
            />
            <BasicInput
                label="Passord"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                id="password"
                placeholder="passord"
                error={errors.password}
                formControl={{ isInvalid: touched.password && !!errors.password }}
            />
            <BasicButton
                isFullWidth
                disabled={!isValid || isSubmitting}
                isLoading={isSubmitting}
                loadingText="Logger inn"
                colorScheme="teal"
                type="submit"
                variant="secondary"
                label="Logg inn"
            />
        </Stack>
    </Form>
);

const SignInFormFormik = withFormik<ISignInFormProps, ISignInFormValues>({
    validate: ({ email, password }) => {
        const errors: FormikErrors<ISignInFormValues> = {};

        errors.email = emailValidator(email);
        errors.password = !password?.length ? '' : undefined;
        const hasErrors = Object.values(errors).some((value) => value !== undefined);

        return hasErrors ? errors : {};
    },
    handleSubmit: async ({ email, password }, { setSubmitting, setFieldError, setStatus }) => {
        setSubmitting(true);
        try {
            await firbeaseInstance.auth().signInWithEmailAndPassword(email, password);
        } catch (error) {
            if (isFirebaseError(error)) {
                switch (error.code) {
                    case 'auth/user-not-found':
                        setFieldError('email', 'En bruker med denne eposten eksisterer ikke');
                        break;
                    case 'auth/wrong-password':
                        setFieldError('password', 'Passordet er feil. Prøv igjen.');
                        break;
                    default:
                        setStatus('api-error');
                        break;
                }
            } else {
                setStatus('unkown-error');
            }
        }
        setSubmitting(false);
    },
    validateOnMount: true,
})(InnerSignInForm);

const SignInForm: React.FC<ISignInFormProps> = ({}) => {
    const onSignInWithGoogle = async () => {
        try {
            const provider = new firebase.auth.GoogleAuthProvider();
            await firbeaseInstance.auth().signInWithRedirect(provider);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Stack spacing={4}>
            <SignInFormFormik />
            <Flex direction="row" align="center" pb="4">
                <Divider flex="1" orientation="horizontal" />
                <Text px="2" fontSize="16px" fontWeight="bold">
                    eller
                </Text>
                <Divider flex="1" orientation="horizontal" />
            </Flex>
            <GoogleLoginButton
                onClick={onSignInWithGoogle}
                text="Logg inn med Google"
                style={{ margin: 0, width: '100%' }}
            />
            <Link href="/#/nullstill-passord" variant="link" color="white">
                Glemt passord?
            </Link>
        </Stack>
    );
};

export default SignInForm;
