import { Flex, Heading } from '@chakra-ui/react';
import React from 'react';
import FinishedSetIllustration from '../atoms/FinishedSetIllustration';

interface IFinishedSetWithHeaderProps {
    fullScore: boolean;
    title: string;
}

export const FinishedSetWithHeader: React.FC<IFinishedSetWithHeaderProps> = ({ fullScore, title }) => {
    return (
        <Flex direction="column" justifyContent="center" alignItems="center">
            <Heading>{title}</Heading>
            <FinishedSetIllustration variant={fullScore ? 'tadaGirl' : 'completedGirl'} />
        </Flex>
    );
};

export default FinishedSetWithHeader;
