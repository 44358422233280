import { useEffect, useState } from 'react';
import { useCurrentUser } from '../context/AuthProvider';
import { incrementSetFinished, updateOverallProgress } from '../utils/firebaseFunctions';
import { Exercise } from '../types/Exercises';

export type ExerciseState = 'touched' | 'correct' | 'wrong' | 'untouched' | 'current' | 'missingUnit';

interface IExerciseProgress {
    exercise: Exercise;
    state: ExerciseState;
    value: string;
}

type ExerciseSetState = 'idle' | 'success' | 'wrong' | 'finished';
type ExerciseSetProgress = IExerciseProgress[];
interface IUseExerciseSet {
    currentExercise: IExerciseProgress | undefined;
    progress: ExerciseSetProgress;
    validate: (answer: string) => void;
    state: ExerciseSetState;
    onNext: () => void;
    onPrev: () => void;
    onExerciseClick: (idx: number) => void;
}

const useExerciseSetProgress = (
    exercises: Exercise[],
    exerciseSetId: number,
    exerciseSetType: string,
): IUseExerciseSet => {
    const [exerciseIdx, setExerciseIdx] = useState<number>(0);
    const currentUser = useCurrentUser();
    const [exerciseState, setExerciseState] = useState<ExerciseSetState>('idle');
    const [exercisesProgress, setExercisesProgress] = useState<ExerciseSetProgress>([]);
    const _exercise: IExerciseProgress | undefined = exercisesProgress[exerciseIdx];

    // create map with exercise progress and reset current exercise
    useEffect(() => {
        setExerciseIdx(0);

        const newStatus = (exercises ?? []).reduce<ExerciseSetProgress>(
            (acc, item) => [...acc, { state: 'untouched', exercise: item, value: '' }],
            [],
        );

        setExercisesProgress(newStatus);
    }, [exercises]);

    // update the progress of an exercise in the current set
    const setExerciseStatus = (exercise: Exercise, status: ExerciseState, value: string) => {
        const exerciseIdx = exercisesProgress.findIndex((value) => value.exercise.id == exercise.id);
        const exerciseProgress = exercisesProgress[exerciseIdx];
        exercisesProgress[exerciseIdx] = {
            ...exerciseProgress,
            state: status,
            value,
        };

        // check if all exercises is answered
        const allExercisesAnswered = !exercisesProgress.some(
            (item) => item.state != 'correct' && item.state != 'wrong',
        );

        let newState: ExerciseSetState;

        switch (status) {
            case 'correct':
                newState = 'success';
                break;
            case 'wrong':
            case 'missingUnit':
                newState = 'wrong';
                break;
            default:
                newState = 'idle';
        }

        setNewExerciseState(allExercisesAnswered ? 'finished' : newState, allExercisesAnswered ? false : true);
        if (allExercisesAnswered && currentUser) {
            updateOverallProgress(
                currentUser.uid,
                exerciseSetType,
                exerciseSetId,
                exercisesProgress.filter((exercise) => exercise.state == 'correct').length,
            );
            incrementSetFinished(currentUser.uid);
        }
        setExercisesProgress(exercisesProgress);
    };

    // validate a answer of a given exercise and update the progress accordingly
    const validate = (answer: string) => {
        const correctAnswers = _exercise?.exercise.answer.units.map(
            (unit) => `${_exercise?.exercise.answer.value}${unit.replaceAll(' ', '')}`,
        );

        const commasReplaced = answer.replace(',', '.');
        const trimmedAnswer = commasReplaced.replaceAll(' ', '');
        const answerValue = parseFloat(trimmedAnswer.replace(/[^0-9.,]+/, ''));
        const answeredUnit = trimmedAnswer.replace(/[0-9.,]+/, '').toLowerCase();
        const providedAnswer = answerValue + answeredUnit;

        const unitIsPresent = answeredUnit.length > 0 ? true : false;
        const answerIsCorrect = correctAnswers.some((correctAnswer) => providedAnswer === correctAnswer);

        const exerciseState: ExerciseState = answerIsCorrect ? 'correct' : unitIsPresent ? 'wrong' : 'missingUnit';

        setExerciseStatus(_exercise.exercise, exerciseState, answer);

        if (answerIsCorrect) {
            setTimeout(onNext, 300);
        }
    };

    const onExerciseClick = (idx: number) => {
        setExerciseIdx(idx);
    };

    const setNewExerciseState = (newState: ExerciseSetState, reset = true) => {
        setExerciseState(newState);

        if (reset) {
            setTimeout(() => {
                setExerciseState('idle');
            }, 1000);
        }
    };

    const onNext = () => {
        if (exerciseIdx == exercisesProgress.length - 1) {
            return;
        }

        setExerciseIdx(exerciseIdx + 1);
    };

    const onPrev = () => {
        if (exerciseIdx == 0) {
            return;
        }

        setExerciseIdx(exerciseIdx - 1);
    };

    return {
        currentExercise: _exercise,
        progress: exercisesProgress,
        onNext,
        onPrev,
        validate,
        onExerciseClick,
        state: exerciseState,
    };
};

export default useExerciseSetProgress;
