import { VStack, Box, Container, Flex, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import HeroHeader from '../atoms/HeroHeader';
import SecondSubHeader from '../atoms/HeroSubHeader';

interface IPageHeroProps {
    heading: string;
    subHeading?: string;
    reverse?: boolean;
}

const PageHero: React.FC<IPageHeroProps> = ({ heading, subHeading, children, reverse = false }) => {
    const stacked = useBreakpointValue({ base: true, xs: true, sm: false, md: false });

    if (stacked) {
        return (
            <VStack py="4" flexDir={reverse ? 'column-reverse' : 'column'} align="center">
                <Box textAlign="center" mt={reverse ? '4' : undefined}>
                    <HeroHeader text={heading} />
                    {subHeading && <SecondSubHeader text={subHeading} />}
                </Box>
                {children}
            </VStack>
        );
    }

    return (
        <Flex py="4" justify="center" align="center">
            <Flex minH="100px" textAlign="center">
                <Container mt="auto" maxW="2xl">
                    {children}
                </Container>
            </Flex>
            <VStack align="flex-start">
                <HeroHeader text={heading} />
                {subHeading && <SecondSubHeader text={subHeading} />}
            </VStack>
        </Flex>
    );
};

export default PageHero;
