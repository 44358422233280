import { SubscriptionPlan } from '../types/Plans';

export const planValidUntil = (plan: SubscriptionPlan): string => {
    const now = new Date();
    now.setMonth(now.getMonth() + plan.months);

    return planDateToString(now);
};

export const planDateToString = (date: Date): string =>
    date.toLocaleDateString('nb', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });
