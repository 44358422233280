import React from 'react';
import { Link, Container, ContainerProps, VStack, Flex, Box } from '@chakra-ui/react';

interface IFooterProps extends ContainerProps {}

export const Footer: React.FC<IFooterProps> = ({ ...rest }) => {
    return (
        <Container maxW="1024px" my="8" {...rest} display="flex" flexDir={['column', 'row']}>
            <VStack alignItems="flex-start" flex="1">
                <Link href="/#/personvern" variant="link" color="white">
                    Personvernerklæring
                </Link>
                <Link href="mailto:raboofda@gmail.com?subject=Support" variant="link" color="white">
                    Kontakt oss
                </Link>
                <Link href="/#/om-oss" variant="link" color="white">
                    Om oss
                </Link>
            </VStack>
            <Flex justify="flex-start" flex="0.5" mt={[4, 0]}>
                <Box fontSize="xs">
                    Vi bruker informasjonskapsler for å skjønne hvordan du bruker tjenesten og for å gjennomføre kjøp.
                    Ved bruk av tjenesten godtar du denne bruken. Du kan lese mer om hvordan informasjonskapsler blir
                    brukt{' '}
                    <Link textDecoration="underline" href="/#/personvern">
                        her.
                    </Link>
                </Box>
            </Flex>
        </Container>
    );
};

export default Footer;
