import { Flex, Heading, AspectRatio, Text, useBreakpointValue, Container, Link } from '@chakra-ui/react';
import React from 'react';
import { ReactComponent as Illustration } from '../../static/Illustrations/sad_error.svg';

const FailedPaymentPage: React.FC = () => {
    const imgWidth = useBreakpointValue({
        base: '300px',
        xs: '100px',
        sm: '300px',
        md: '500px',
    });

    return (
        <Container maxW="1024px" textAlign="center">
            <Flex flexDir="column" align="center">
                <Heading as="h1" size="4xl" mt="10">
                    Oops!
                </Heading>
                <AspectRatio w={imgWidth} ratio={16 / 9}>
                    <Illustration style={{ objectFit: 'contain' }} />
                </AspectRatio>
                <Text fontSize="2xl" maxW={['100%', '70%']} my="8">
                    Vi beklager, men kjøpet ditt kunne ikke gjennomføres. Prøv igjen senere, eller{' '}
                    <Link color="secondary" href="mailto:raboofda@gmail.com?subject=Support">
                        kontakt oss.
                    </Link>
                </Text>
            </Flex>
        </Container>
    );
};

export default FailedPaymentPage;
