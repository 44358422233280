import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import CustomColors from '../../theme/CustomColors';

const AboutUsInfoBoxes: React.FC = () => {
    return (
        <>
            <Box
                p={5}
                shadow="md"
                borderWidth="1px"
                flex="1"
                borderRadius="md"
                minH="140px"
                minW="343px"
                bg={CustomColors.secondaryLight}
                color="black"
            >
                <Heading as="h2" size="md">
                    100 oppgavesett
                </Heading>
                <Text>Velg mellom 100 forskjellige oppgavesett på tvers av pensum</Text>
            </Box>
            <Box
                p={5}
                shadow="md"
                borderWidth="1px"
                flex="1"
                borderRadius="md"
                minH="140px"
                minW="343px"
                bg={CustomColors.secondaryLight}
                color="black"
                textAlign="center"
            >
                <Heading as="h2" size="md">
                    1800+ oppgaver
                </Heading>
                <Text>Utforsk 1800 forskjellige oppgaver som er kvalitetssikret</Text>
            </Box>
            <Box
                p={5}
                textAlign="center"
                shadow="md"
                borderWidth="1px"
                flex="1"
                borderRadius="md"
                minH="140px"
                minW="343px"
                bg={CustomColors.secondaryLight}
                color="black"
            >
                <Heading as="h2" size="md">
                    Regnerelger
                </Heading>
                <Text>Lær nyttige regneregler innenfor 6 forskjellige temaer relevant for medikamentregning</Text>
            </Box>
        </>
    );
};

export default AboutUsInfoBoxes;
