import { Heading, HStack, VStack } from '@chakra-ui/react';
import React from 'react';

interface IInformationWithLeadingTextProps {
    heading: string;
    icon?: React.ReactElement;
}

const InformationWithLeadingText: React.FC<IInformationWithLeadingTextProps> = ({ icon, heading, children }) => {
    if (icon) {
        return (
            <VStack align="flex-start">
                <HStack>
                    {icon}
                    <Heading size="sm">{heading}</Heading>
                </HStack>
                {children}
            </VStack>
        );
    }
    return (
        <VStack>
            <Heading size="sm">{heading}</Heading>
            {children}
        </VStack>
    );
};

export default InformationWithLeadingText;
