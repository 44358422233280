import React from 'react';
import { Form, FormikErrors, FormikProps, withFormik } from 'formik';
import {
    AspectRatio,
    Container,
    Flex,
    FormControl,
    FormErrorMessage,
    Heading,
    Select,
    Textarea,
} from '@chakra-ui/react';
import {
    emailValidator,
    nameValidator,
    contactUsInputValidator,
    contactUsDropdownValidator,
} from '../../utils/validators';
import BasicInput from '../atoms/Input';
import BasicButton from '../atoms/Button';
import { ReactComponent as Illustration } from '../../static/Illustrations/mail_sent.svg';

interface IContactUsValues {
    firstName: string;
    email: string;
    dropdown: string;
    input: string;
}
interface IContactUsFormProps {}

const InnerContactUsForm: React.FC<FormikProps<IContactUsValues>> = ({
    handleChange,
    handleBlur,
    errors,
    touched,
    status,
}) => {
    if (status == 'success') {
        return (
            <Container textAlign="center" mt="24">
                <Heading as="h1">Takk for din hendvendelse! </Heading>
                <Heading as="h1" size="md">
                    Du vil motta svar innen 48 timer
                </Heading>
                <AspectRatio w="100%" ratio={16 / 9} mt="8">
                    <Illustration width="100px" style={{ objectFit: 'contain' }} />
                </AspectRatio>
            </Container>
        );
    }

    return (
        <Form>
            <Container maxW="720px" mt="8">
                <Heading textAlign="center" as="h1" mb="8">
                    Kontakt oss
                </Heading>
                <Flex flexDir={['column', 'row']} gridGap={['2', '8']}>
                    <BasicInput
                        label="Fornavn"
                        id="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Ola Nordmann"
                        error={errors.firstName}
                        formControl={{ isInvalid: touched.firstName && !!errors.firstName }}
                    />
                    <BasicInput
                        label="Mail"
                        id="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="ola@nordmann.no"
                        error={errors.email}
                        formControl={{ isInvalid: touched.email && !!errors.email }}
                    />
                </Flex>
                <FormControl isInvalid={touched.dropdown && !!errors.dropdown}>
                    <Select
                        isRequired
                        id="dropdown"
                        mt="6"
                        background="white"
                        color="black"
                        onBlur={handleBlur}
                        onChange={handleChange}
                    >
                        <option value="" selected disabled>
                            Hva kan vi hjelpe deg med?
                        </option>

                        <option value="payment">Betaling</option>
                        <option value="excerciseSets">Oppgaver</option>
                        <option value="other">Annet</option>
                    </Select>
                    <FormErrorMessage fontWeight="bold" color="white">
                        {errors.dropdown}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={touched.input && !!errors.input}>
                    <Textarea
                        id="input"
                        mt="6"
                        background="white"
                        color="black"
                        minH="200px"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.input && !!errors.input}
                        placeholder="Hva tenker du på?"
                    />
                    <FormErrorMessage fontWeight="bold" color="white">
                        {errors.input}
                    </FormErrorMessage>
                </FormControl>
                <BasicButton variant="secondary" type="submit" label="Send" />
            </Container>
        </Form>
    );
};

const ContactUsForm = withFormik<IContactUsFormProps, IContactUsValues>({
    validate: ({ firstName, email, dropdown, input }) => {
        const errors: FormikErrors<IContactUsValues> = {};

        errors.firstName = nameValidator(firstName);
        errors.email = emailValidator(email);
        errors.input = contactUsInputValidator(input);
        errors.dropdown = contactUsDropdownValidator(dropdown);

        const hasErrors = Object.values(errors).some((value) => value !== undefined);

        return hasErrors ? errors : {};
    },
    handleSubmit: (values, { setSubmitting, setStatus }) => {
        setSubmitting(false);
        setStatus('success');
    },
    validateOnMount: true,
})(InnerContactUsForm);

export default ContactUsForm;
