import React from 'react';
import { Stack, Alert } from '@chakra-ui/react';
import firbeaseInstance, { isFirebaseError } from '../utils/firebase';
import BasicInput from '../components/atoms/Input';
import { Form, FormikErrors, FormikProps, withFormik } from 'formik';
import { passwordValidator } from '../utils/validators';
import BasicButton from '../components/atoms/Button';

interface INewPasswordForm {
    password: string;
    passwordRepeated: string;
}

interface INewPasswordFormProps {
    resetCode: string;
    onSuccess: () => void;
}

const InnerNewPasswordForm: React.FC<FormikProps<INewPasswordForm>> = ({
    handleBlur,
    handleChange,
    errors,
    touched,
    isValid,
    isSubmitting,
    status,
}) => (
    <Form>
        <Stack spacing={4}>
            {status == 'api-error' && (
                <Alert hidden={status != 'api-error'} status="error" bg="red.500">
                    En ukjent feil oppsto. Prøv igjen senere.
                </Alert>
            )}
            <BasicInput
                label="Nytt passord"
                id="password"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                isDisabled={isSubmitting}
                error={errors.password}
                formControl={{ isInvalid: touched.password && !!errors.password }}
            />
            <BasicInput
                label="Gjenta ditt nye passord"
                id="passwordRepeated"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                isDisabled={isSubmitting}
                error={errors.passwordRepeated}
                formControl={{ isInvalid: touched.passwordRepeated && !!errors.passwordRepeated }}
            />
            <BasicButton
                isFullWidth
                disabled={!isValid || isSubmitting}
                isLoading={isSubmitting}
                loadingText="Endrer passord"
                colorScheme="teal"
                type="submit"
                variant="secondary"
                label="Endre passord"
            />
        </Stack>
    </Form>
);

const NewPasswordForm = withFormik<INewPasswordFormProps, INewPasswordForm>({
    validate: ({ password, passwordRepeated }) => {
        const errors: FormikErrors<INewPasswordForm> = {};

        errors.password = passwordValidator(password);
        errors.passwordRepeated = passwordValidator(password, passwordRepeated ?? '');
        const hasErrors = Object.values(errors).some((value) => value !== undefined);

        return hasErrors ? errors : {};
    },
    handleSubmit: async ({ password }, { setSubmitting, setFieldError, setStatus, props }) => {
        setSubmitting(true);
        try {
            await firbeaseInstance.auth().confirmPasswordReset(props.resetCode, password);
            props.onSuccess();
        } catch (error) {
            console.log(error);
            if (isFirebaseError(error)) {
                switch (error.code) {
                    case 'auth/user-not-found':
                        setFieldError('email', 'En bruker med denne eposten eksisterer ikke');
                        break;
                    case 'auth/wrong-password':
                        setFieldError('password', 'Passordet er feil. Prøv igjen.');
                        break;
                    default:
                        setStatus('api-error');
                        break;
                }
            } else {
                setStatus('unkown-error');
            }
        }
        setSubmitting(false);
    },
    validateOnMount: true,
})(InnerNewPasswordForm);

export default NewPasswordForm;
