import React, { ReactElement, useState, useRef } from 'react';
import { HStack } from '@chakra-ui/react';
import CustomButton from '../atoms/Button';
import { Parser } from 'expr-eval';
import BasicInput from '../atoms/Input';

export interface CalculatorInputProps {
    onCalculate: (result: string) => void;
    unitType: string;
}

const parser = new Parser({
    operators: {
        subtract: true,
        multiply: true,
        divide: true,
        power: true,
        add: true,
        in: false,
        logical: false,
        factorial: false,
        remainder: false,
        assignment: false,
        comparison: false,
        concatenate: false,
        conditional: false,
    },
});

function CalculatorInput(props: CalculatorInputProps): ReactElement {
    const [value, setValue] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);

    const handleClick = () => {
        let res;
        try {
            const replaceCommas = value.replaceAll(',', '.');
            res = parser.parse(replaceCommas).evaluate();
        } catch (err) {
            res = 'Ikke gyldig input';
        }
        props.onCalculate(value + ' = ' + res);
        setValue('');
        inputRef.current?.focus();
    };

    const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key == 'Enter') {
            handleClick();
        }
    };
    return (
        <HStack spacing="10px">
            <BasicInput
                ref={inputRef}
                placeholder="Skriv regnestykke f.eks 2+3*2"
                textAlign="left"
                width="250px"
                onKeyPress={onKeyPress}
                value={value}
                onChange={(e) => setValue(e.target.value)}
            />
            <CustomButton variant="secondary" label="Regn ut" onClick={handleClick} />
        </HStack>
    );
}

export default CalculatorInput;
