import { Box, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import LandingPageBox from '../atoms/LandingPageBox';
import HeroHeader from '../atoms/HeroHeader';
import { useExerciseTopics } from '../../hooks/swr';

interface IExerciseSetsOverviewProps {}

export const ExerciseSetsOverview: React.FC<IExerciseSetsOverviewProps> = ({}) => {
    const topics = useExerciseTopics();
    const history = useHistory();

    return (
        <Box mt="16">
            <HeroHeader text="Utvalgte tema" fontWeight="bold" />
            <SimpleGrid columns={[1, 3, 3, 4, 4]} spacingY="8" spacingX="8" my={['8', '10']}>
                {topics.slice(0, 4).map((topic, i) => (
                    <LandingPageBox
                        key={i}
                        title={topic.title}
                        description={topic.description}
                        secondlineDescription={'20 oppgavesett'}
                        onClick={() => history.push('/temaer/' + topic.url)}
                    />
                ))}
            </SimpleGrid>
        </Box>
    );
};

export default ExerciseSetsOverview;
