import { Heading, TextProps } from '@chakra-ui/react';
import React from 'react';

interface IHeroSubHeaderProps extends TextProps {
    text: string;
}

export const HeroSubHeader: React.FC<IHeroSubHeaderProps> = ({ text, ...rest }) => {
    return (
        <Heading
            as="h2"
            fontSize={['24px', '24px', '28px', '38px']}
            color="rgba(255,255,255,0.8)"
            fontWeight="bold"
            {...rest}
        >
            {text}
        </Heading>
    );
};

export default HeroSubHeader;
