import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { ExerciseState } from '../../hooks/useExerciseSetProgress';

interface ITaskNumberProps {
    taskStatus: ExerciseState;
    taskName: string;
    currentExcercise: boolean;
    onClick?: () => void;
}

const TaskNumberColors: Record<ExerciseState, string> = {
    correct: '#6FC697',
    touched: '',
    wrong: '#EB5757',
    untouched: '',
    missingUnit: '',
    current: '#E3CA98',
};

export const TaskNumber: React.FC<ITaskNumberProps> = ({ taskStatus, taskName, currentExcercise, onClick }) => {
    const drawBorder = taskStatus == 'untouched' || taskStatus == 'missingUnit';
    return (
        <Flex
            borderRadius="8"
            bg={currentExcercise ? 'primary' : ''}
            justifyContent="flex-start"
            px={['4px', '10px']}
            py={['4px', '8px']}
            alignItems="center"
            onClick={onClick}
            cursor={onClick ? 'pointer' : 'default'}
        >
            <HStack spacing={['12px', '20px']}>
                <Box
                    w={['15px', '20px']}
                    h={['15px', '20px']}
                    borderRadius="4"
                    bg={TaskNumberColors[taskStatus]}
                    border={drawBorder ? '1px' : '0'}
                    borderColor={drawBorder ? 'white' : ''}
                ></Box>
                <Text fontSize={['12px', '16px']} color="white">
                    {taskName}
                </Text>
            </HStack>
        </Flex>
    );
};

export default TaskNumber;
