import { Box, Heading, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import CustomColors from '../../theme/CustomColors';
import HeroHeader from './HeroHeader';

const StyrkeProsentRules: React.FC = () => (
    <>
        <HeroHeader text="Styrke og prosent" fontSize={['32', '36']} fontWeight="bold" fontFamily="lato" pt="5" />
        <VStack pt={5}>
            <SimpleGrid columns={1} gap={10} justifyItems="center" pt={5}>
                <Box
                    borderRadius="1rem"
                    w="fit-content"
                    border="1px"
                    bg={CustomColors.primaryLight}
                    borderColor="white"
                    padding="20px"
                >
                    <Heading textShadow="lg" size="md" textAlign="left" fontWeight="600" fontStyle="normal" mt="20px">
                        {'Masseprosent, volumprosent og masse-/volumprosent'}
                    </Heading>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'Noen ganger er styrken på et legemiddel oppgitt i prosent. ' +
                            'Prosenten sier hvor stor del av en mengde legemiddel det er som er virkestoff.'}
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {
                            'Det finnes tre måter å angi styrke som prosent. Masseprosent, volumprosent og masse-/volumprosent.'
                        }
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'Masseprosent sier hvor mange gram virkestoff det finnes i 100 gram legemiddel. En styrke på 50% betyr at ' +
                            'halvparten av et legemiddel er virkestoff. Hvis du har 100 gram av et legemiddel, er det da 50 gram virkestoff. ' +
                            'Har du 1 gram legemiddel, er det 0,5 gram (500mg) virkestoff.'}
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'For volumprosent er det akkurat likt, bortsett fra at her måles legemidlet i milliliter og ikke gram. Volumprosenten ' +
                            'sier hvor mange milliliter virkestoff det finnes i 100 milliliter legemiddel. Det vil si at en styrke på 50% betyr at halvparten ' +
                            'av volumet, altså antall milliliter, er virkestoff. 100 milliliter legemiddel inneholder 50 milliliter virkestoff.'}
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'Volum-/masseprosent er den vanligste. Her angir prosenten hvor mange gram virkestoff det finnes i 100 milliliter legemiddel. En ' +
                            'styrke på 50% betyr at det er 50 gram virkestoff i 100 milliliter legemiddel. 1 milliliter legemiddel inneholder da 0,5g (500mg) ' +
                            'virkestoff, eller 500 mg/ml. Denne er nok kanskje ikke like intuitiv, men en tommelfingerregel er at 1% = 10 mg/ml.'}
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {'Oppsummert så er det hvor mange prosent av en gitt mengde legemiddel et virkestoff er. Her er det en fordel å mestre ' +
                            'prosentrening.'}
                    </Text>
                </Box>
            </SimpleGrid>
        </VStack>
    </>
);

export default StyrkeProsentRules;
