import confetti from 'canvas-confetti';
import { useCallback } from 'react';

interface IUseConfetti {
    play: (x?: number, y?: number) => void;
    realistic: (x?: number, y?: number) => void;
    fireworks: (x?: number, y?: number) => void;
}

const randomInRange = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
};

const useConfetti = (): IUseConfetti => {
    const play = useCallback((x?: number, y?: number) => {
        confetti({
            particleCount: 100,
            spread: 70,
            origin: { x: x, y: y ?? 0.6 },
        });
    }, []);

    const realistic = useCallback((x?: number, y?: number) => {
        const fire = (particleRatio: number, opts: confetti.Options) => {
            const count = 100;
            const defaults = {
                origin: { x: x, y: y ?? 0.6 },
            };

            confetti(
                Object.assign({}, defaults, opts, {
                    particleCount: Math.floor(count * particleRatio),
                }),
            );
        };
        fire(0.25, {
            spread: 26,
            startVelocity: 55,
        });
        fire(0.2, {
            spread: 60,
        });
        fire(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8,
        });
        fire(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2,
        });
        fire(0.1, {
            spread: 120,
            startVelocity: 45,
        });
    }, []);

    const fireworks = useCallback(() => {
        const duration = 5 * 1000;
        const animationEnd = Date.now() + duration;
        const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

        const interval: NodeJS.Timeout = setInterval(function () {
            const timeLeft = animationEnd - Date.now();

            if (timeLeft <= 0) {
                return clearInterval(interval);
            }

            const particleCount = 50 * (timeLeft / duration);

            // since particles fall down, start a bit higher than random
            confetti(
                Object.assign({}, defaults, {
                    particleCount,
                    origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
                }),
            );
            confetti(
                Object.assign({}, defaults, {
                    particleCount,
                    origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
                }),
            );
        }, 250);
    }, []);

    return { play, realistic, fireworks };
};

export default useConfetti;
