import React from 'react';
import { ButtonProps, Button, Icon } from '@chakra-ui/react';
import { IoPersonCircleSharp } from 'react-icons/io5';
import firebase from 'firebase/app';

interface ISignInProfileButtonProps extends ButtonProps {
    currentUser?: firebase.User | null;
}

const ProfileButton: React.FC<ISignInProfileButtonProps> = ({ currentUser, ...rest }) => (
    <Button
        variant="ghost"
        leftIcon={currentUser ? <Icon as={IoPersonCircleSharp} /> : undefined}
        fontSize={['sm', 'sm', 'sm', 'md']}
        _hover={{ bg: 'primary' }}
        _active={{
            bg: 'primaryLight',
            borderBottomRadius: 0,
        }}
        aria-label="profil-informasjon"
        {...rest}
    >
        {currentUser?.displayName ?? 'Logg inn'}
    </Button>
);

export default ProfileButton;
