import { Container, Flex } from '@chakra-ui/react';
import React from 'react';
import { ReactComponent as CompletedGirl } from '../../static/Illustrations/completed_girl.svg';
import { ReactComponent as TadaGirl } from '../../static/Illustrations/tada_girl.svg';

interface IFinishedSetIllustrationProps {
    variant: 'tadaGirl' | 'completedGirl';
}

export const FinishedSetIllustration: React.FC<IFinishedSetIllustrationProps> = ({ variant }) => {
    if (variant == 'tadaGirl') {
        return (
            <Flex minH="100px" mt="6" textAlign="center">
                <Container mt="auto" maxW="2xl">
                    <TadaGirl />
                </Container>
            </Flex>
        );
    } else {
        return (
            <Flex minH="300px" mt="6" textAlign="center">
                <Container mt="auto" maxW="2xl">
                    <CompletedGirl />
                </Container>
            </Flex>
        );
    }
};

export default FinishedSetIllustration;
