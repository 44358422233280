import { Box, Flex, Container } from '@chakra-ui/layout';
import { useOutsideClick } from '@chakra-ui/react';
import { Collapse } from '@chakra-ui/transition';
import React from 'react';
import { useCurrentUser } from '../../context/AuthProvider';
import AuthenticationTabs from '../../forms/AuthenticationTabs';
import AccountInformation from '../organisms/AccountInformation';

interface IPageHeaderAccountContentProps {
    isOpen: boolean;
    onClose: () => void;
}

const PageHeaderAccountContent: React.FC<IPageHeaderAccountContentProps> = ({ isOpen, onClose }) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const currentUser = useCurrentUser();

    // the handler must use a setTimeout to make it able to press the button
    // again to collapse the profile information
    useOutsideClick({
        ref: ref,
        handler: () =>
            setTimeout(() => {
                isOpen && onClose();
            }, 100),
    });

    return (
        <Box w="100%" ref={ref}>
            <Collapse in={isOpen} animateOpacity>
                <Flex flexGrow={1} w="100%" color="white" bg="primaryLight" py="1em">
                    <Container maxW="1024px" display="flex" justifyContent="flex-end">
                        {currentUser ? <AccountInformation onItemClick={onClose} /> : <AuthenticationTabs />}
                    </Container>
                </Flex>
            </Collapse>
        </Box>
    );
};

export default PageHeaderAccountContent;
