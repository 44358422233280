import React from 'react';
import {
    AspectRatio,
    Container,
    useBreakpointValue,
    SimpleGrid,
    Box,
    Heading,
    Flex,
    VStack,
    Text,
} from '@chakra-ui/react';
import { ReactComponent as Illustration } from '../../static/Illustrations/education_light.svg';
import PageHero from '../molecules/PageHero';
import { useHistory, useParams } from 'react-router-dom';
import { useExerciseSetsForTopic } from '../../hooks/swr';
import { ExerciseSetOption } from '../../types/Exercises';
import ExerciseSetPreFetch from '../../utils/ExerciseSetPreFetch';
import { useOverallProgress } from '../../hooks/useOverallProgress';
import ExerciseCard from '../atoms/ExerciseCard';

const ExerciseSets: React.FC = () => {
    const { topic } = useParams<{ topic: string }>();
    const exerciseSets = useExerciseSetsForTopic(topic);
    const progress = useOverallProgress();

    const boxSize = useBreakpointValue({
        base: '300px',
    });

    return (
        <Container maxW="1024px">
            <PageHero heading="Oppgavesett" reverse={true}>
                <AspectRatio w={boxSize} ratio={16 / 9}>
                    <Illustration style={{ objectFit: 'contain' }} />
                </AspectRatio>
            </PageHero>
            <Flex justify="center" mb="6">
                <VStack textAlign="center" maxW="400px">
                    <Heading as="h1">{exerciseSets[0]?.title.slice(0, -1)}</Heading>
                    <Text fontSize="18px">{exerciseSets[0]?.description}</Text>
                </VStack>
            </Flex>
            <SimpleGrid columns={[1, 1, 1, 1, 1]} spacingY="6" spacingX="8">
                {exerciseSets.map((item) => (
                    <ExerciseSetCard
                        key={item.id}
                        {...item}
                        solvedByUser={progress[topic]?.[item.id]?.nCorrectAnswers}
                        tries={progress[topic]?.[item.id]?.tries}
                    />
                ))}
            </SimpleGrid>
        </Container>
    );
};

interface ExerciseSetCard extends ExerciseSetOption {
    solvedByUser: number;
    tries: number;
}

const ExerciseSetCard: React.FC<ExerciseSetCard> = ({
    title,
    description,
    num_exercises: numExercises,
    url,
    solvedByUser,
    tries,
}) => {
    const history = useHistory();
    const { topic } = useParams<{ topic: string }>();

    const onExerciseSetCardClick = () => {
        history.push(`/temaer/${topic}/${url.replace('.json', '')}`);
    };

    return (
        <Box key={url} display="flex" flex="1">
            <ExerciseCard
                key={url + '-card'}
                title={title}
                description={description}
                secondlineDescription={`${numExercises} oppgaver`}
                onClick={onExerciseSetCardClick}
                solvedLastTime={solvedByUser ? solvedByUser : 0}
                tries={tries ? tries : 0}
                label="Gå til oppgavesett"
            />
            <ExerciseSetPreFetch key={url + '-prefetch'} topic={topic} set={url.replace('.json', '')} />
        </Box>
    );
};

export default ExerciseSets;
