import React from 'react';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { useHistory } from 'react-router';
import { useCurrentUser } from '../../context/AuthProvider';
import { useIsMobile } from '../../hooks/useIsMobile';
import PageHeaderContent from '../molecules/PageHeaderContent';
import PageHeaderContentMobile from '../molecules/PageHeaderContentMobile';

const DISCLOSURE_PADDING = '15px';

interface IPageHeaderProps {}

export const PageHeader: React.FC<IPageHeaderProps> = () => {
    const isMobile = useIsMobile();
    const history = useHistory();
    const disclosure = useDisclosure();

    const navigateHome = () => {
        history.push('/');
    };

    const currentUser = useCurrentUser();

    if (isMobile) {
        return (
            <Flex p="0" align="flex-start" direction="column" py="1.5em">
                <PageHeaderContentMobile
                    onHeaderClick={navigateHome}
                    currentUser={currentUser}
                    disclosure={disclosure}
                    disclousePadding={DISCLOSURE_PADDING}
                />
            </Flex>
        );
    }

    return (
        <Flex p="0" align="flex-start" direction="column" py="1.5em">
            <PageHeaderContent
                onHeaderClick={navigateHome}
                currentUser={currentUser}
                disclosure={disclosure}
                disclousePadding={DISCLOSURE_PADDING}
            />
        </Flex>
    );
};

export default PageHeader;
