import { Box, Divider, Heading, HStack, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import CustomColors from '../../theme/CustomColors';
import { DSMTriangle } from './DSMTriangle';
import HeroHeader from './HeroHeader';

interface DoseStyrkeMEngdeRulesProps {
    isMobile: boolean;
}
const DoseText = () => {
    return (
        <Box
            borderRadius="1rem"
            w="fit-content"
            border="1px"
            bg={CustomColors.primaryLight}
            borderColor="white"
            padding="20px"
        >
            <Heading textShadow="lg" size="md" textAlign="left" fontWeight="600" fontStyle="normal" mt="20px">
                {'Finne dosen'}
            </Heading>
            <Text fontSize="16" mt="10px" lineHeight="19px">
                {'Hvis styrken og mengden er kjent fra oppgaveteksten og en ønsker å ' +
                    'finne dosen markert i oransje i trekanten bruker man de kjente verdiene' +
                    ' til å finne dosen.'}
            </Text>
            <Text fontSize="16" mt="10px" lineHeight="19px">
                {'I dette tilfellet står de to verdiene vi kjenner ' +
                    'ved siden av hverandre med grønt i trekanten. Fordi de står ' +
                    'ved siden av hverandre skal vi gange de to verdiene for ' +
                    ' å finne dosen. Prøv å skrive inn noen tall i trekanten for å se.'}
            </Text>
            <Text fontSize="16" mt="10px" lineHeight="19px">
                {'Prøv å skrive inn noen tall i trekanten for å se hva som skjer.'}
            </Text>
        </Box>
    );
};

const StyrkeText = () => {
    return (
        <Box
            borderRadius="1rem"
            w="fit-content"
            border="1px"
            bg={CustomColors.primaryLight}
            borderColor="white"
            padding="20px"
        >
            <Heading textShadow="lg" size="md" textAlign="left" fontWeight="600" fontStyle="normal" mt="20px">
                {'Finne styrken'}
            </Heading>
            <Text fontSize="16" mt="10px" lineHeight="19px">
                {'Hvis mengden og dosen er kjent fra oppgaveteksten og en ønsker å ' +
                    'finne styrken markert i oransje i trekanten bruker man de kjente verdiene' +
                    ' til å finne styrken.'}
            </Text>
            <Text fontSize="16" mt="10px" lineHeight="19px">
                {'I dette tilfellet står de to verdiene vi kjenner ' +
                    'ovenfor hverandre med grønt i trekanten. Fordi de står ' +
                    'ovenfor hverandre skal vi dele den øverste verdien med' +
                    ' den under for å finne mengden. Man kan se på det som at de to kjente verdiene danner en ' +
                    'brøk med dosen øverst og derfor deler vi dosen på mengden i dette tilfelelt.'}
            </Text>
            <Text fontSize="16" mt="10px" lineHeight="19px">
                {'Prøv å skrive inn noen tall i trekanten for å se hva som skjer.'}
            </Text>
        </Box>
    );
};

const MengdeText = () => {
    return (
        <Box
            borderRadius="1rem"
            w="fit-content"
            border="1px"
            bg={CustomColors.primaryLight}
            borderColor="white"
            padding="20px"
        >
            <Heading textShadow="lg" size="md" textAlign="left" fontWeight="600" fontStyle="normal" mt="20px">
                {'Finne mengden'}
            </Heading>
            <Text fontSize="16" mt="10px" lineHeight="19px">
                {'Hvis styrken og dosen er kjent fra oppgaveteksten og en ønsker å ' +
                    'finne mengden markert i oransje i trekanten bruker man de kjente verdiene' +
                    ' til å finne dosen.'}
            </Text>
            <Text fontSize="16" mt="10px" lineHeight="19px">
                {'I dette tilfellet står de to verdiene vi kjenner ' +
                    'ovenfor hverandre med grønt i trekanten. Fordi de står ' +
                    'ovenfor hverandre skal vi dele den øverste verdien med' +
                    ' den under for å finne mengden. Også her kan man se på det som at de to kjente verdiene danner en ' +
                    'brøk med dosen øverst og derfor deler vi dosen på styrken i dette tilfelelt.'}
            </Text>
            <Text fontSize="16" mt="10px" lineHeight="19px">
                {'Prøv å skrive inn noen tall i trekanten for å se hva som skjer.'}
            </Text>
        </Box>
    );
};
const DoseStyrkeMengdeRules: React.ElementType<DoseStyrkeMEngdeRulesProps> = ({ isMobile }) => (
    <>
        <HeroHeader text="Dose - Styrke - Mengde" fontSize={['32', '36']} fontWeight="bold" fontFamily="lato" pt="5" />
        <VStack pt={5}>
            <SimpleGrid columns={1} gap={10} justifyItems="center" pt={5}>
                {isMobile ? (
                    <>
                        <VStack>
                            <DoseText />
                            <DSMTriangle size="sm" doseBgColor="orange" />
                        </VStack>
                        <Divider />
                        <VStack>
                            <StyrkeText />
                            <DSMTriangle size="sm" styrkeBgColor="orange" />
                        </VStack>
                        <Divider />
                        <VStack>
                            <MengdeText />
                            <DSMTriangle size="sm" mengdeBgColor="orange" />
                        </VStack>
                        <Divider />
                    </>
                ) : (
                    <>
                        <HStack>
                            <DoseText />
                            <DSMTriangle size="sm" doseBgColor="orange" />
                        </HStack>
                        <Divider />
                        <HStack>
                            <StyrkeText />
                            <DSMTriangle size="sm" styrkeBgColor="orange" />
                        </HStack>
                        <Divider />
                        <HStack>
                            <MengdeText />
                            <DSMTriangle size="sm" mengdeBgColor="orange" />
                        </HStack>
                        <Divider />
                    </>
                )}
            </SimpleGrid>
        </VStack>
    </>
);

export default DoseStyrkeMengdeRules;
