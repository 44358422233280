import { Flex, Container, AspectRatio, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import PageHero from '../molecules/PageHero';
import { ReactComponent as Illustration } from '../../static/Illustrations/climb_books.svg';
import ExerciseOverview from './ExerciseOverview';
import UserProgress from '../molecules/UserProgress';
import SubscriptionInfoCards from '../molecules/SubscriptionInfoCards';
import TopicsPreFetch from '../../utils/TopicsPreFetch';

const LandingPage: React.FC = () => {
    const boxSize = useBreakpointValue({
        base: '250px',
        md: '250px',
        lg: '300px',
    });

    return (
        <Flex direction="column">
            <TopicsPreFetch />
            <Container maxW="1024px">
                <PageHero heading="Vær forberedt til eksamen" subHeading="- ved å regne flere oppgavesett">
                    <AspectRatio w={boxSize} ratio={16 / 9}>
                        <Illustration width="100px" style={{ objectFit: 'contain' }} />
                    </AspectRatio>
                </PageHero>
                <SubscriptionInfoCards />
                <UserProgress />
                <ExerciseOverview />
            </Container>
        </Flex>
    );
};

export default LandingPage;
