import { Box, Heading, Table, Tbody, Text, Td, Th, Thead, Tr, VStack, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import CustomColors from '../../theme/CustomColors';
import HeroHeader from './HeroHeader';

interface OmregningTidRulesProps {
    isMobile: boolean;
}

const OmregningTidTable: React.ElementType<OmregningTidRulesProps> = ({ isMobile }) => (
    <>
        <HeroHeader text="Omregning tid" fontSize={['32', '36']} fontWeight="bold" fontFamily="lato" pt="5" />
        <VStack pt={5}>
            <Table variant="simple" size={isMobile ? 'sm' : 'md'}>
                <Thead>
                    <Tr>
                        <Th textColor="white" fontSize="l">
                            FRA
                        </Th>
                        <Th textColor="white" fontSize="l">
                            TIL
                        </Th>
                        <Th textColor="white" fontSize="l">
                            METODE
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td>Timer (t)</Td>
                        <Td>minutter (min)</Td>
                        <Td>Gange med 60 (1t x 60 = 60minutter)</Td>
                    </Tr>
                    <Tr>
                        <Td>minutter (min)</Td>
                        <Td>sekunder (s)</Td>
                        <Td>Gange med 60 (2min x 60 = 120sekunder)</Td>
                    </Tr>
                    <Tr>
                        <Td>sekunder (s)</Td>
                        <Td>minutter (m)</Td>
                        <Td>Dele med 60 (240s / 60 = 4min)</Td>
                    </Tr>
                    <Tr>
                        <Td>minutter (min)</Td>
                        <Td>timer (t)</Td>
                        <Td>Dele med 60 (30min / 60 = 0,5t)</Td>
                    </Tr>
                </Tbody>
            </Table>
            <SimpleGrid columns={[1, 1, 2, 2]} gap={10} justifyItems="center" pt={5}>
                <Box
                    borderRadius="1rem"
                    w="fit-content"
                    border="1px"
                    bg={CustomColors.primaryLight}
                    borderColor="white"
                    padding="20px"
                >
                    <Heading textShadow="lg" size="md" textAlign="left" fontWeight="600" fontStyle="normal" mt="20px">
                        {'Regning med tid'}
                    </Heading>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {
                            'Når man ganger med 60 for å gjøre om timer til minutter er det fordi det er 60 minutter i 1 time.'
                        }
                    </Text>
                    <Text fontSize="16" mt="10px" lineHeight="19px">
                        {
                            'Det samme gjelder for sekunder, vi ganger med 60 for å gjøre minutter om til sekunder fordi det er 60 sekunder i 1 minutt.'
                        }
                    </Text>
                </Box>
            </SimpleGrid>
        </VStack>
    </>
);

export default OmregningTidTable;
