export type CloudErrorCodes =
    | 'promo-already-used'
    | 'email-not-eligable'
    | 'email-already-redeemed'
    | 'invalid-promo-code'
    | 'unkown error';

type CloudFunctionSuccess = {
    type: 'success';
};

type CloudFunctionError = {
    type: 'error';
    message: string;
    code: CloudErrorCodes;
};

export const CLOUD_ERROR_MESSAGES: Record<CloudErrorCodes, string> = {
    'email-not-eligable': 'Epostadressen er ikke gyldig for gratis tilgang. Om dette er feil ta kontakt med oss.',
    'invalid-promo-code': 'Tilgangskoden er ikke gyldig. Dobbeltsjekk koden og prøv igjen.',
    'email-already-redeemed':
        'Epostadressen som er oppgitt har allerede motatt og brukt en gratis tilgangskode. Ta kontakt med oss for mer informasjon.',
    'promo-already-used': 'Tilgangskoden er allerede brukt.',
    'unkown error': 'En ukjent feil oppstod, prøv igjen senere.',
};

export type CloudFunctionResponse = CloudFunctionSuccess | CloudFunctionError;
