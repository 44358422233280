import {
    VStack,
    HStack,
    useBoolean,
    Box,
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import { useIsSignedIn } from '../../context/AuthProvider';
import { useProducts } from '../../context/StaticProvider';
import AuthenticationTabs from '../../forms/AuthenticationTabs';
import PlanCard from '../molecules/PlanCard';

interface ISubscriptionsOverviewProps {
    onSuccessUrlFallback?: string;
}

const SubscriptionsOverview: React.FC<ISubscriptionsOverviewProps> = ({ onSuccessUrlFallback }) => {
    const isMobile = useBreakpointValue({ base: true, xs: true, sm: false, md: false });
    const products = useProducts();
    const [buyDisabled, { on }] = useBoolean();
    const isSignedIn = useIsSignedIn();

    const onBuyClick = () => {
        on();
    };

    return (
        <>
            {!isSignedIn && (
                <Alert
                    status="error"
                    borderRadius="md"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    height="auto"
                    w={['100%', '50%']}
                    mx="auto"
                    bg="primaryLight"
                    textColor="white"
                    pt="6"
                    mb="4"
                >
                    <AlertIcon color="secondaryLight" boxSize="40px" mr={0} />
                    <AlertTitle mt={4} mb={1} fontSize="lg">
                        Pålogging nødvendig
                    </AlertTitle>
                    <AlertDescription maxWidth="sm" justifyItems="center">
                        For å kunne kjøpe tilgang må du være logget på eller opprette en konto.
                    </AlertDescription>
                    <AuthenticationTabs />
                </Alert>
            )}
            {isMobile ? (
                <VStack spacing="8">
                    {products
                        .sort((a, b) => a.months - b.months)
                        .map((item) => (
                            <PlanCard
                                disabled={buyDisabled}
                                onBuy={onBuyClick}
                                plan={item}
                                onSuccessUrlFallback={onSuccessUrlFallback}
                                key={`card-${item.months}`}
                            />
                        ))}
                    )
                </VStack>
            ) : (
                <HStack justify="space-between">
                    {products
                        .sort((a, b) => a.months - b.months)
                        .map((item) => (
                            <PlanCard
                                disabled={buyDisabled}
                                onBuy={onBuyClick}
                                plan={item}
                                onSuccessUrlFallback={onSuccessUrlFallback}
                                key={`card-${item.months}`}
                            />
                        ))}
                    )
                </HStack>
            )}
            {!isSignedIn && <Box bg="red" zIndex={10} />}
        </>
    );
};

export default SubscriptionsOverview;
