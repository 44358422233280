import React from 'react';
import { Container, HStack, VStack, Box, Text } from '@chakra-ui/react';
import { IPageHeaderContentProps } from '../../interfaces/common';
import PageMenu from '../organisms/PageMenu';
import PageHeaderAccountContent from '../atoms/PageHeaderAccountContent';
import ProfileButton from '../atoms/ProfileButton';

const PageHeaderContentMobile: React.FC<IPageHeaderContentProps> = ({
    onHeaderClick,
    disclosure,
    currentUser,
    disclousePadding,
}) => (
    <>
        <Container maxW="1024px" alignSelf="center">
            <HStack justifyContent="space-between">
                <Text
                    as="h1"
                    fontWeight="700"
                    fontSize={['xl', '3xl']}
                    lineHeight={0}
                    cursor="pointer"
                    onClick={onHeaderClick}
                    pb={disclosure.isOpen ? disclousePadding : undefined}
                >
                    Medikamentregning
                </Text>
                <VStack spacing={0} p={0}>
                    <ProfileButton
                        isActive={disclosure.isOpen}
                        onClick={disclosure.onToggle}
                        currentUser={currentUser}
                    />
                    <Box hidden={!disclosure.isOpen} bg="primaryLight" w="100%" h={disclousePadding} />
                </VStack>
            </HStack>
        </Container>
        <PageHeaderAccountContent isOpen={disclosure.isOpen} onClose={disclosure.onClose} />
        <Container maxW="1024px" alignSelf="center" mt={disclosure.isOpen ? 2 : undefined}>
            <PageMenu />
        </Container>
    </>
);

export default PageHeaderContentMobile;
