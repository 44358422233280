import { Heading, TextProps } from '@chakra-ui/react';
import React from 'react';

interface IHeroHeaderProps extends TextProps {
    text: string;
}

export const HeroHeader: React.FC<IHeroHeaderProps> = ({ text, ...rest }) => {
    return (
        <Heading
            fontSize={['28px', '28px', '32px', '48px']}
            color="white"
            fontFamily="Lato"
            fontWeight="extrabold"
            {...rest}
        >
            {text}
        </Heading>
    );
};

export default HeroHeader;
