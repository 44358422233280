/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import NonValidSubscriptionPage from '../components/pages/NonValidSubscriptionPage';
import { useCurrentUserHasValidSubscription } from '../context/AuthProvider';

interface IActiveSubscriptionProtectedPageProps extends RouteProps {
    component: any;
}

const ActiveSubscriptionProtectedPage: React.FC<IActiveSubscriptionProtectedPageProps> = ({
    component: Page,
    ...rest
}) => {
    const validSubscription = useCurrentUserHasValidSubscription();

    return (
        <Route
            {...rest}
            render={(routeProps) => (validSubscription ? <Page {...routeProps} /> : <NonValidSubscriptionPage />)}
        />
    );
};

export default ActiveSubscriptionProtectedPage;
