import React from 'react';
import { Box, Container, Heading, HStack, Text, VStack } from '@chakra-ui/react';
import { useIsMobile } from '../../hooks/useIsMobile';
import AboutUsInfoBoxes from '../molecules/AboutUsInfoBoxes';

const AboutUs: React.FC = () => {
    const isMobile = useIsMobile();

    return (
        <Container maxW="1024px" textAlign="center" pt={['15px', '50px']}>
            <Heading as="h1" size="2xl">
                Vi ønsker
            </Heading>
            <Heading as="h2" size="md" mt="4">
                at du skal mestre medikamentregning!
            </Heading>
            <Box mt="12" mb="16">
                <Heading as="h1" size="2xl">
                    Om oss
                </Heading>
                <Text fontSize="xl" mt="4" textAlign="left">
                    I samarbeid med Universitetet i Tromsø har vi laget en løsning for å hjelpe deg som student til å
                    mestre og bli trygg i medikamentregning. Sammen med forelesere og studenter har vi kommet fram til
                    en digital løsning som skal være enkel å bruke og skaper et realistisk læringsmiljø fram mot
                    eksamen. Ved å generere oppgaver basert på realistiske scenarier og verdier, er vårt mål at du som
                    student skal få et best mulig læringsutbytte av å benytte deg av Medikamentregning. Siden er
                    utviklet både for mobil, nettbrett og pc. Dermed kan du regne oppgavesett når og hvor det passer deg
                    best.
                </Text>
            </Box>
            {isMobile ? (
                <VStack>
                    <AboutUsInfoBoxes />
                </VStack>
            ) : (
                <HStack>
                    <AboutUsInfoBoxes />
                </HStack>
            )}
        </Container>
    );
};

export default AboutUs;
