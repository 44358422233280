import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import React from 'react';
import RegisterForm from './RegisterForm';
import SignInForm from './SignInForm';

interface IAuthenticationTabsProps {}

const AuthenticationTabs: React.FC<IAuthenticationTabsProps> = ({}) => {
    return (
        <Tabs defaultIndex={0} variant="unstyled" w={['100%', '300px']}>
            <TabList justifyContent="center" color="gray.100">
                <Tab
                    flex="1"
                    mr="1"
                    borderBottomWidth="2px"
                    _selected={{ color: 'white', fontWeight: '600', borderColor: 'white' }}
                >
                    Logg inn
                </Tab>
                <Tab
                    flex="1"
                    ml="1"
                    borderBottomWidth="2px"
                    _selected={{ color: 'white', fontWeight: '600', borderColor: 'white' }}
                >
                    Registrer
                </Tab>
            </TabList>
            <TabPanels>
                <TabPanel px="0">
                    <SignInForm />
                </TabPanel>
                <TabPanel px="0">
                    <RegisterForm />
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};

export default AuthenticationTabs;
