import React from 'react';
import { Container, AspectRatio, useBreakpointValue, Text, Box, Heading, Link } from '@chakra-ui/react';
import PageHero from '../molecules/PageHero';
import { ReactComponent as Illustration } from '../../static/Illustrations/Privacy.svg';

const PrivacyPage: React.FC = () => {
    const boxSize = useBreakpointValue({
        base: '300px',
    });

    return (
        <Container maxW="1024px">
            <PageHero heading="Personvern" reverse={true}>
                <AspectRatio w={boxSize} ratio={16 / 9}>
                    <Illustration style={{ objectFit: 'contain' }} />
                </AspectRatio>
            </PageHero>
            <Box w="100%" mx="auto">
                <Text>
                    Vi bruker informasjonskapsler for å analysere hvordan du bruker siden, la deg registrere deg, la deg
                    logg deg inn og for å gjennomføre kjøp av tilgang til betalt innhold på siden. For å analysere
                    hvordan du bruker nettsiden vår bruker vi Google Analytics, og for å la deg registrere og logge deg
                    inn bruker vi Google Firebase. Informasjonskapselen fra google vil du finne som{' '}
                    <Box as="span" display="inline" paddingInline="1.5" bg="primaryLight">
                        _ga
                    </Box>{' '}
                    cookies.
                    <br />
                    <br />
                    Cookies relatert til kjøp av tilgang til betalt innhold på siden vil du finne som{' '}
                    <Box as="span" display="inline" paddingInline="1.5" bg="primaryLight">
                        __stripe
                    </Box>{' '}
                    cookies.
                </Text>
                <Heading as="h2" size="lg" pt="4" pb="2">
                    Dataen din
                </Heading>
                <Text>
                    Vi bruker ikke dataen din til noe spesielt. Når du logger deg inn eller registrerer deg på siden får
                    vi navn og eposten din.
                    <br />
                    <br />
                    Ved kjøp av tilgang til innhold på siden vår, vil det bli opprettet et kundenummer knyttet mot
                    epostadressen din for å sjekke om du har gyldig tilgang til innholdet.
                    <br />
                    <br />
                    All data vi har lagret om deg kan du få utlevert ved å sende oss en mail på{' '}
                    <Link isExternal={true} href="mailto:raboofda@gmail.com?subject=Spørsmål vedrørende personvern">
                        raboofda@gmail.com
                    </Link>{' '}
                    så fikser vi det.
                </Text>
            </Box>
        </Container>
    );
};

export default PrivacyPage;
