import React from 'react';
import { useExerciseSetsForTopic } from '../hooks/swr';
import { ExerciseTopic } from '../types/Exercises';

interface ITopicPreFetchProps {
    topic: ExerciseTopic;
}

const TopicPreFetch: React.FC<ITopicPreFetchProps> = ({ topic }) => {
    // Call on exercise hook which will prefetch and store sets using
    // swr
    useExerciseSetsForTopic(topic.url.replace('.json', ''));

    return null;
};

export default TopicPreFetch;
