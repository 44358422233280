import React from 'react';
import { HStack } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import MenuItem from '../atoms/MenuItem';

const PageMenu: React.FC = () => {
    const location = useLocation();
    const history = useHistory();

    const onMenuItemClick = (id: string) => {
        switch (id) {
            case 'hjem':
                history.push('/');
                break;
            case 'temaer':
                history.push('/temaer');
                break;
            case 'regneregler':
                history.push('/regneregler');
                break;
            default:
                break;
        }
    };

    const { pathname } = location;

    return (
        <HStack h="100%" align="center">
            <MenuItem label="Hjem" id="hjem" onClick={onMenuItemClick} selected={pathname == '/'} />
            <MenuItem label="Temaer" id="temaer" onClick={onMenuItemClick} selected={pathname.includes('temaer')} />
            <MenuItem
                label="Regneregler"
                id="regneregler"
                onClick={onMenuItemClick}
                selected={pathname.includes('regneregler')}
            />
        </HStack>
    );
};

export default PageMenu;
