const emailValidator = (email: string): string | undefined => {
    const emailReg = /.+@.+\.[A-Za-z]+$/;

    if (email == undefined) {
        return 'Kan ikke være tomt.';
    }

    if (email.length < 4) {
        return 'Eposten er ikke gyldig.';
    }

    if (!new RegExp(emailReg).test(email)) {
        return 'Eposten er ikke gyldig.';
    }

    return undefined;
};

const stringLengthValidator = (value: string, minLength: number): string | undefined => {
    if (value === undefined || value.length < minLength) {
        return `Må være minst ${minLength} tegn`;
    }

    return undefined;
};

const passwordValidator = (password: string, repeatedPassword?: string): string | undefined => {
    const validation = [
        { key: /\d+/g, msg: 'Passordet må inneholde minst et tall.' },
        { key: /[a-zæøå]/g, msg: 'Passordet må inneholde små bokstaver.' },
        { key: /[A-ZÆØÅ]/g, msg: 'Passordet må inneholde store bokstaver.' },
        { key: /^.{8,}$/g, msg: 'Passordet må inneholde minst 8 bokstaver.' },
    ];

    for (const item in validation) {
        if (!new RegExp(validation[item].key).test(password)) {
            return validation[item].msg;
        }
    }

    if (repeatedPassword !== undefined && (repeatedPassword === '' || password != repeatedPassword)) {
        return 'Passordene er ikke like.';
    }

    return undefined;
};

const nameValidator = (name: string): string | undefined => {
    const nameReg = /^[a-zæøå]/gi;

    if (name == undefined || name.length == 0) {
        return 'Må fylles ut.';
    }

    if (!new RegExp(nameReg).test(name)) {
        return 'Kun bokstavene A-Å.';
    }

    return undefined;
};

const contactUsInputValidator = (input: string): string | undefined => {
    if (input == undefined || input.length == 0) {
        return 'Må fylles ut.';
    }

    return undefined;
};

const contactUsDropdownValidator = (input: string): string | undefined => {
    if (input == undefined || input.length == 0) {
        return 'Velg et alternativ fra listen.';
    }

    return undefined;
};

export {
    nameValidator,
    stringLengthValidator,
    passwordValidator,
    emailValidator,
    contactUsInputValidator,
    contactUsDropdownValidator,
};
