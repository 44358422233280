import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Alert, AlertIcon, AlertDescription, Text, Button, Box, ButtonProps } from '@chakra-ui/react';
import React from 'react';
import { useCurrentUserData } from '../../context/AuthProvider';

interface ISubscriptionInformationProps {
    // An optional callback to call when the button is pressed
    onButtonClick?: () => void;
}

const ButtonToSubscriptionPage: React.FC<ButtonProps> = ({ children, ...rest }) => (
    <Button
        variant="ghost"
        p="0"
        aria-label="kjøp tilgang"
        as="a"
        rightIcon={<ArrowForwardIcon />}
        ml="8"
        size="sm"
        borderRadius="0"
        transition="none"
        _hover={{ bg: 'none', borderBottom: '2px', borderBottomColor: 'white' }}
        _active={{ bg: 'none' }}
        href="/#/kjop-tilgang"
        {...rest}
    >
        {children}
    </Button>
);

const SubscriptionInformation: React.FC<ISubscriptionInformationProps> = ({ onButtonClick }) => {
    const data = useCurrentUserData();

    if (data.type == 'inactive') {
        return (
            <Alert status="warning" color="black" borderRadius="lg" fontSize="sm">
                <AlertIcon />
                <AlertDescription>Du har ikke tilgang til alt innhold.</AlertDescription>
                <ButtonToSubscriptionPage onClick={onButtonClick}>Få tilgang</ButtonToSubscriptionPage>
            </Alert>
        );
    }

    const validUntilString = data.validUntil.toLocaleDateString('nb-NO', {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });

    return (
        <>
            <Text>
                Gyldig til:
                <Box ml="8px" fontWeight="bold" as="span" fontSize="1.2rem">
                    {validUntilString}
                </Box>
            </Text>
            <ButtonToSubscriptionPage onClick={onButtonClick}>Forleng tilgang</ButtonToSubscriptionPage>
        </>
    );
};

export default SubscriptionInformation;
