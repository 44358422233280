import {
    Flex,
    Container,
    AspectRatio,
    useBreakpointValue,
    Center,
    Heading,
    Box,
    Circle,
    VStack,
    HStack,
    Text,
} from '@chakra-ui/react';
import React from 'react';
import PageHero from '../molecules/PageHero';
import { ReactComponent as Illustration } from '../../static/Illustrations/discount.svg';
import PromoCodeTabs from '../../forms/PromoCodeTabs';

const UniversityPromoPage: React.FC = () => {
    const boxSize = useBreakpointValue({
        base: '250px',
        md: '250px',
        lg: '300px',
    });

    return (
        <Flex direction="column">
            <Container maxW="1024px">
                <PageHero heading="Universitetsavtaler">
                    <AspectRatio w={boxSize} ratio={16 / 9}>
                        <Illustration width="100px" style={{ objectFit: 'contain' }} />
                    </AspectRatio>
                </PageHero>
                <Center minH="200px" py="4" flexDir="column">
                    <Heading size="md" pt="2" maxW={['100%', '70%']} textAlign="center">
                        Medikamentregning har avtaler med flere universiteter i Norge som gir deg som{' '}
                        <Box as="span" fontWeight="extrabold">
                            student
                        </Box>{' '}
                        fri tilgang til{' '}
                        <Box as="span" textDecoration="underline">
                            alle
                        </Box>{' '}
                        oppgavesett, oppgaver og regneregler det semesteret du tar faget.
                    </Heading>
                </Center>
                <Heading size="md">Slik søker du om tilgang:</Heading>
                <VStack spacing="4" py="4" align="flex-start">
                    <HStack>
                        <Circle size="40px" bg="primaryLight" color="white">
                            1
                        </Circle>
                        <Text>
                            Skriv inn din{' '}
                            <Box as="span" fontWeight="bold">
                                universitets-epost
                            </Box>{' '}
                            i feltet under og klikk{' '}
                            <Box as="span" fontWeight="bold">
                                {'"Søk"'}.
                            </Box>
                        </Text>
                    </HStack>
                    <HStack>
                        <Circle size="40px" bg="primaryLight" color="white">
                            2
                        </Circle>
                        <Text>
                            Om Medikamentregning har en avtale med ditt universitet vil du motta en tilgangskode på{' '}
                            <Box as="span" fontWeight="bold">
                                universitets-eposten
                            </Box>{' '}
                            som gir deg{' '}
                            <Box as="span" fontWeight="bold">
                                6 måneders fri tilgang.
                            </Box>
                        </Text>
                    </HStack>
                    <HStack>
                        <Circle size="40px" bg="primaryLight" color="white">
                            3
                        </Circle>
                        <Text>
                            Velg{' '}
                            <Box as="span" fontWeight="bold">
                                {'"Har tilgangskode"'}
                            </Box>{' '}
                            under og lim inn tilgangskoden du mottok på eposten i tilgangsfeltet.
                        </Text>
                    </HStack>
                    <HStack>
                        <Circle size="40px" bg="primaryLight" color="white">
                            4
                        </Circle>
                        <Text>
                            Klikk{' '}
                            <Box as="span" fontWeight="bold">
                                {'"Bruk tilgangskode."'}
                            </Box>
                        </Text>
                    </HStack>
                    <HStack>
                        <Circle size="40px" bg="primaryLight" color="white">
                            5
                        </Circle>
                        <Text>Lykke til med øvingen!</Text>
                    </HStack>
                </VStack>
                <Center>
                    <PromoCodeTabs />
                </Center>
            </Container>
        </Flex>
    );
};

export default UniversityPromoPage;
