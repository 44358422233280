import React from 'react';
import { Stack, Alert } from '@chakra-ui/react';
import { Form, FormikErrors, FormikProps, withFormik } from 'formik';
import firbeaseInstance, { isFirebaseError } from '../utils/firebase';
import { emailValidator, nameValidator, passwordValidator } from '../utils/validators';
import BasicInput from '../components/atoms/Input';
import BasicButton from '../components/atoms/Button';

interface IRegisterFormValues {
    fullName: string;
    email: string;
    password: string;
    repatePassword: string;
}

interface IRegisterFormProps {}

const InnerRegisterForm: React.FC<FormikProps<IRegisterFormValues>> = ({
    handleChange,
    handleBlur,
    errors,
    touched,
    isValid,
    isSubmitting,
    status,
}) => (
    <Form>
        <Stack spacing={4}>
            {status == 'api-error' && (
                <Alert status="error" bg="red.500">
                    En ukjent feil oppsto ved opprettelse av konto. Prøv igjen senere.
                </Alert>
            )}
            <BasicInput
                label="Fullt navn"
                id="fullName"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="f.eks Ola Nordmann"
                error={errors.fullName}
                formControl={{ isInvalid: touched.fullName && !!errors.fullName }}
            />
            <BasicInput
                label="Epostadressen"
                id="email"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="f.eks ola@nordmann.no"
                error={errors.email}
                formControl={{ isInvalid: touched.email && !!errors.email }}
            />
            <BasicInput
                label="Passord"
                type="password"
                id="password"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="******"
                error={errors.password}
                formControl={{ isInvalid: touched.password && !!errors.password }}
            />
            <BasicInput
                label="Gjenta passord"
                id="repatePassword"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="******"
                type="password"
                error={errors.repatePassword}
                formControl={{ isInvalid: touched.repatePassword && !!errors.repatePassword }}
            />
            <BasicButton
                isLoading={isSubmitting}
                loadingText="Oppretter konto"
                disabled={!isValid || isSubmitting}
                colorScheme="teal"
                type="submit"
                variant="secondary"
                label="Opprett konto"
            />
        </Stack>
    </Form>
);

const RegisterForm = withFormik<IRegisterFormProps, IRegisterFormValues>({
    validate: ({ fullName, password, repatePassword, email }) => {
        const errors: FormikErrors<IRegisterFormValues> = {};

        errors.fullName = nameValidator(fullName);
        errors.password = passwordValidator(password, repatePassword);
        errors.repatePassword = password !== repatePassword ? 'Passordene er ikke like.' : undefined;
        errors.email = emailValidator(email);

        const hasErrors = Object.values(errors).some((value) => value !== undefined);

        return hasErrors ? errors : {};
    },
    handleSubmit: async ({ fullName, email, password }, { setSubmitting, setFieldError, setStatus }) => {
        setSubmitting(true);
        try {
            await firbeaseInstance
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then((userCredential) => userCredential.user?.updateProfile({ displayName: fullName }));
        } catch (error) {
            if (isFirebaseError(error) && error.code == 'auth/email-already-in-use') {
                setFieldError('email', 'Eposten er allerede i bruk.');
            } else {
                setStatus('api-error');
            }
            setStatus('api-error');
        }
        setSubmitting(false);
    },
    validateOnMount: true,
})(InnerRegisterForm);

export default RegisterForm;
