import React from 'react';
import * as CSS from 'csstype';
import { StackProps, Box, VStack, Heading } from '@chakra-ui/layout';
import { ResponsiveValue } from '@chakra-ui/styled-system';
import DSMTriangleInput from './Input';

interface IDSMTriangleElement {
    id: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void;
    clipPath: string;
    placeItems: ResponsiveValue<CSS.Property.PlaceItems>;
    color: string;
    fontSize: string;
    value: string;
    label: string;
    stackProps?: StackProps;
    bgColor?: string;
}

const DSMTriangleElement: React.FC<IDSMTriangleElement> = ({
    color,
    placeItems,
    clipPath,
    onChange,
    onFocus,
    onBlur,
    id,
    label,
    value,
    fontSize,
    stackProps,
    bgColor,
}) => {
    return (
        <Box width="100%" height="100%" filter="drop-shadow(5px 5px 5px  rgba(0,0,0,0.2))">
            <Box
                bg={bgColor ? bgColor : 'primaryLight'}
                width="100%"
                height="100%"
                clipPath={clipPath}
                display="grid"
                placeItems={placeItems}
            >
                <VStack width="70%" {...stackProps}>
                    <DSMTriangleInput
                        color={color}
                        id={id}
                        value={value}
                        onChange={onChange}
                        fontSize={fontSize}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                    <Heading color={color} fontSize={fontSize}>
                        {label}
                    </Heading>
                </VStack>
            </Box>
        </Box>
    );
};

export default DSMTriangleElement;
