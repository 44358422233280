import { FormControl, FormControlProps, FormErrorMessage, FormLabel, Input, InputProps } from '@chakra-ui/react';
import React from 'react';

interface IBasicInputProps extends InputProps {
    label?: string;
    error?: string;
    formControl?: FormControlProps;
    wrongAnswer?: boolean;
}

const BasicInput = React.forwardRef<HTMLInputElement, IBasicInputProps>(
    ({ label, error, formControl, wrongAnswer, ...rest }, ref) => {
        if (label) {
            return (
                <FormControl {...formControl}>
                    <FormLabel>{label}</FormLabel>
                    <Input
                        ref={ref}
                        _placeholder={{
                            color: '#828282',
                            fontSize: '16px',
                        }}
                        borderRadius="12px"
                        lineHeight="19px"
                        fontSize="16px"
                        background={wrongAnswer ? 'red.400' : 'white'}
                        px="20px"
                        py="8px"
                        color="black"
                        {...rest}
                    />
                    <FormErrorMessage fontWeight="bold" color="white">
                        {error}
                    </FormErrorMessage>
                </FormControl>
            );
        }

        return (
            <FormControl {...formControl}>
                <Input
                    ref={ref}
                    _placeholder={{
                        color: '#828282',
                        fontSize: '16px',
                    }}
                    borderRadius="12px"
                    lineHeight="19px"
                    fontSize="16px"
                    background={wrongAnswer ? 'red.400' : 'white'}
                    px="20px"
                    py="8px"
                    color="black"
                    {...rest}
                />
                <FormErrorMessage fontWeight="bold" color="white">
                    {error}
                </FormErrorMessage>
            </FormControl>
        );
    },
);

BasicInput.displayName = 'BasicInput';

export default BasicInput;
