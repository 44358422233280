import { loadStripe } from '@stripe/stripe-js';
import { createStripeCheckout } from './firebaseFunctions';

export const redirectToStripeCheckout = async (productCode: string, successUrl?: string): Promise<void> => {
    try {
        const session = await createStripeCheckout(productCode, successUrl);
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? '');

        await stripe?.redirectToCheckout({
            sessionId: session.data.id,
        });
    } catch (error) {
        console.log(error);
    }
};
