import React from 'react';
import { Box, Container, Heading, Flex, useBoolean, Text } from '@chakra-ui/react';
import ResetPasswordForm from '../../forms/ResetPasswordForm';
import { useQueryParams } from '../../utils/useQueryParams';
import NewPasswordForm from '../../forms/NewPasswordForm';
import { useIsSignedIn } from '../../context/AuthProvider';
import { Redirect } from 'react-router';

export const ResetPasswordPage: React.FC = () => {
    const search = useQueryParams();
    const isSignedIn = useIsSignedIn();
    const [resetSuccess, resetPasswordState] = useBoolean();
    const [requestResetSuccess, requestResetState] = useBoolean();

    const resetCode = search.get('oobCode');

    if (isSignedIn) {
        return <Redirect to="/" />;
    }

    if (resetCode) {
        return (
            <Container maxW="1024px" textAlign="center" pt={['15px', '50px']}>
                <Heading as="h1" size="2xl">
                    Sett nytt passord
                </Heading>
                <Flex justify="center">
                    <Box w="60%" mt="12" mb="16" hidden={resetSuccess}>
                        <NewPasswordForm resetCode={resetCode} onSuccess={resetPasswordState.on} />
                    </Box>
                    <Box mt="12" hidden={!resetSuccess}>
                        <Text>Passordet ditt er nullstilt.</Text>
                    </Box>
                </Flex>
            </Container>
        );
    }

    return (
        <Container maxW="1024px" textAlign="center" pt={['15px', '50px']}>
            <Heading as="h1" size="2xl">
                Glemt passord
            </Heading>
            <Heading as="h2" size="md" mt="4">
                Skriv inn din e-post i feltet under, for å nullstille passordet ditt.
            </Heading>
            <Flex justify="center">
                <Box w="60%" mt="12" mb="16" hidden={requestResetSuccess}>
                    <ResetPasswordForm onSuccess={requestResetState.on} />
                </Box>
                <Box mt="12" hidden={!requestResetSuccess}>
                    <Text>En lenke for å nullstille passordet ditt er sendt til eposten du oppga.</Text>
                </Box>
            </Flex>
        </Container>
    );
};
