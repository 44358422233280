import React from 'react';
import {
    VStack,
    HStack,
    Heading,
    Text,
    Box,
    useDisclosure,
    UseModalProps,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useClipboard,
} from '@chakra-ui/react';
import useCalculator from '../../hooks/useCalculator';
import CalculatorInput from '../molecules/CalculatorInput';
import { ReactComponent as CalculatorIcon } from '../../static/icons/calculator.svg';
import { RepeatClockIcon } from '@chakra-ui/icons';
import BasicButton from '../atoms/Button';

export interface ICalculatorProps {
    unitType: string;
}

export const Calculator: React.FC<ICalculatorProps> = ({ unitType }) => {
    const [history, onCalculate] = useCalculator();
    const modalControl = useDisclosure();

    const mostRecentCalculate: string | undefined = history[history.length - 1];

    return (
        <VStack bg="primaryLight" borderRadius="12px" p="16px" align="stretch" width="fit-content" spacing="10px">
            <HStack>
                <CalculatorIcon />
                <Heading fontWeight="semiBold" fontSize="18px">
                    Kalkulator
                </Heading>
                <Box flex={1} />
                {history.length > 1 && (
                    <HStack onClick={modalControl.onToggle} p="4px" borderRadius="4" _hover={{ bg: 'primary' }}>
                        <RepeatClockIcon justifySelf="flex-end" />
                        <Text>Historie</Text>
                    </HStack>
                )}
            </HStack>
            <CalculatorInput unitType={unitType} onCalculate={onCalculate} />
            {mostRecentCalculate != undefined && (
                <Heading fontSize="18px" fontWeight="semibold">
                    {mostRecentCalculate}
                </Heading>
            )}
            <CalculatorHistory1 history={history} {...modalControl} />
        </VStack>
    );
};

interface ICalculatorHistoryProps extends UseModalProps {
    history: string[];
}

const CalculatorHistory1: React.FC<ICalculatorHistoryProps> = ({ onClose, isOpen, history }) => {
    return (
        <Modal isCentered onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom">
            <ModalOverlay />
            <ModalContent bg="primary">
                <ModalHeader>Regneresultater</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack align="stretch">
                        {history.map((item, i) => (
                            <CalculatorHistoryRow key={`calculator-result-${i}`} value={item} />
                        ))}
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <BasicButton label="Lukk" onClick={onClose} variant="primary" />
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

interface ICalculatorHistoryRowProps {
    value: string;
}

const CalculatorHistoryRow: React.FC<ICalculatorHistoryRowProps> = ({ value }) => {
    const { hasCopied, onCopy } = useClipboard(value);

    return (
        <HStack justify="space-between">
            <Text>{value}</Text>
            <BasicButton variant="secondary" label={hasCopied ? 'Kopiert' : 'Kopier'} onClick={onCopy} />
        </HStack>
    );
};

export default Calculator;
