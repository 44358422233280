import { Grid, GridItem } from '@chakra-ui/react';
import React, { useState } from 'react';
import DSMTriangleElement from './Element';

type DSMTriangleSizes = 'sm' | 'md' | 'lg';

interface IDSMTriangle {
    size: DSMTriangleSizes;
    doseBgColor?: string;
    styrkeBgColor?: string;
    mengdeBgColor?: string;
}

interface IDSMTriangleSizeConfiguration {
    fontSize: string;
    padding: string;
    width: number;
}

const TriangleSizeConfigurations: Record<DSMTriangleSizes, IDSMTriangleSizeConfiguration> = {
    lg: { fontSize: '1.25em', padding: '1.25em', width: 200 },
    md: { fontSize: '1em', padding: '1.25em', width: 150 },
    sm: { fontSize: '1em', padding: '1em', width: 110 },
};

const DSMTriangle: React.FC<IDSMTriangle> = ({ size, doseBgColor, styrkeBgColor, mengdeBgColor }) => {
    const [doseValue, setDoseValue] = useState('');
    const [mengdeValue, setMengdeValue] = useState('');
    const [styrkeValue, setStyrkeValue] = useState('');
    const [termHistory, setTermHistory] = useState<string[]>([]);

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, id } = event.target;

        switch (id) {
            case 'dose':
                setDoseValue(value);
                break;
            case 'styrke':
                setStyrkeValue(value);
                break;
            case 'mengde':
                setMengdeValue(value);
                break;
            default:
                break;
        }

        if (termHistory.length == 2 && termHistory[0] != id) {
            termHistory.unshift(id);
            termHistory.pop();
        } else if (termHistory.length == 1 && termHistory[0] != id) {
            termHistory.unshift(id);
        } else if (termHistory.length == 0) {
            termHistory.push(id);
        }

        setTermHistory(termHistory);

        if (termHistory.length == 2 && value != '') {
            let res;
            const key = termHistory.join(',');
            switch (key) {
                case 'styrke,dose':
                    res = parseFloat(doseValue) / parseFloat(value);
                    setMengdeValue(res.toFixed(2).toString());
                    break;
                case 'dose,styrke':
                    res = parseFloat(value) / parseFloat(styrkeValue);
                    setMengdeValue(res.toFixed(2).toString());
                    break;
                case 'mengde,styrke':
                    res = parseFloat(styrkeValue) * parseFloat(value);
                    setDoseValue(res.toFixed(2).toString());
                    break;
                case 'styrke,mengde':
                    res = parseFloat(value) * parseFloat(mengdeValue);
                    setDoseValue(res.toFixed(2).toString());
                    break;
                case 'dose,mengde':
                    res = parseFloat(value) / parseFloat(mengdeValue);
                    setStyrkeValue(res.toFixed(2).toString());
                    break;
                case 'mengde,dose':
                    res = parseFloat(doseValue) / parseFloat(value);
                    setStyrkeValue(res.toFixed(2).toString());
                    break;
                default:
                    break;
            }
        }
    };

    const onFocusHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.placeholder = '';
    };

    const onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.placeholder = '0.0';
    };

    const highLightColor = 'secondary';

    const { fontSize, width } = TriangleSizeConfigurations[size];

    return (
        <Grid templateColumns={`repeat(2,${width}px)`} templateRows={`repeat(2,${width}px)`} gap="1">
            <GridItem colSpan={2}>
                <DSMTriangleElement
                    color={
                        termHistory.length == 2 && !termHistory.includes('dose') && !doseBgColor
                            ? highLightColor
                            : 'white'
                    }
                    placeItems="center"
                    clipPath="polygon(50% 0, 50% 0%, 75% 100%, 25% 100%)"
                    onChange={onChangeHandler}
                    onBlur={onBlur}
                    onFocus={onFocusHandler}
                    id="dose"
                    fontSize={fontSize}
                    value={doseValue}
                    label="DOSE"
                    stackProps={{ mt: '1em' }}
                    bgColor={doseBgColor}
                />
            </GridItem>
            <GridItem colSpan={1}>
                <DSMTriangleElement
                    color={
                        termHistory.length == 2 && !termHistory.includes('styrke') && !styrkeBgColor
                            ? highLightColor
                            : 'white'
                    }
                    placeItems="center end"
                    clipPath="polygon(50% 0, 100% 0%, 100% 100%, 0% 100%)"
                    onChange={onChangeHandler}
                    onBlur={onBlur}
                    onFocus={onFocusHandler}
                    id="styrke"
                    fontSize={fontSize}
                    value={styrkeValue}
                    label="STYRKE"
                    stackProps={{ pr: '0.5em' }}
                    bgColor={styrkeBgColor}
                />
            </GridItem>
            <GridItem colSpan={1}>
                <DSMTriangleElement
                    color={
                        termHistory.length == 2 && !termHistory.includes('mengde') && mengdeBgColor
                            ? highLightColor
                            : 'white'
                    }
                    placeItems="center start"
                    clipPath="polygon(0 0, 50% 0%, 100% 100%, 0% 100%)"
                    onChange={onChangeHandler}
                    onBlur={onBlur}
                    onFocus={onFocusHandler}
                    id="mengde"
                    fontSize={fontSize}
                    value={mengdeValue}
                    label="MENGDE"
                    stackProps={{ pl: '0.5em' }}
                    bgColor={mengdeBgColor}
                />
            </GridItem>
        </Grid>
    );
};

export default DSMTriangle;
