import React from 'react';
import { Container, Flex, Box, VStack, Text } from '@chakra-ui/layout';
import { IPageHeaderContentProps } from '../../interfaces/common';
import PageHeaderAccountContent from '../atoms/PageHeaderAccountContent';
import PageMenu from '../organisms/PageMenu';
import ProfileButton from '../atoms/ProfileButton';

const PageHeaderContent: React.FC<IPageHeaderContentProps> = ({
    disclosure,
    onHeaderClick,
    currentUser,
    disclousePadding,
}) => (
    <>
        <Container maxW="1024px" alignSelf="center">
            <Flex justify="space-between" alignContent="center" alignSelf="center" color="white">
                <Flex align="center" _focus={{ outline: 'none' }}>
                    <Text
                        as="h1"
                        fontWeight="700"
                        fontSize={['xl', '3xl']}
                        lineHeight={0}
                        cursor="pointer"
                        onClick={onHeaderClick}
                        pb={disclosure.isOpen ? disclousePadding : undefined}
                    >
                        Medikamentregning
                    </Text>
                </Flex>
                <Box pb={disclosure.isOpen ? disclousePadding : undefined}>
                    <PageMenu />
                </Box>
                <VStack spacing={0} p={0}>
                    <ProfileButton
                        isActive={disclosure.isOpen}
                        onClick={disclosure.onToggle}
                        currentUser={currentUser}
                    />
                    <Box hidden={!disclosure.isOpen} bg="primaryLight" w="100%" h={disclousePadding} />
                </VStack>
            </Flex>
        </Container>
        <PageHeaderAccountContent isOpen={disclosure.isOpen} onClose={disclosure.onClose} />
    </>
);

export default PageHeaderContent;
