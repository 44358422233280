import React, { useEffect } from 'react';
import { FormikProps, Form, withFormik, FormikErrors } from 'formik';
import BasicInput from '../atoms/Input';
import { Textarea } from '@chakra-ui/textarea';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { stringLengthValidator } from '../../utils/validators';
import { useDisclosure } from '@chakra-ui/hooks';
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/modal';
import FeedbackButton from '../atoms/FeedbackButton';
import BasicButton from '../atoms/Button';
import { postFeedback } from '../../utils/firebaseFunctions';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { useIsSignedIn } from '../../context/AuthProvider';
import { useToast } from '@chakra-ui/toast';

interface IFeedbackFormValues {
    title: string;
    description: string;
}

interface IFeedbackFormProps {}

const InnerFeedbackForm: React.FC<FormikProps<IFeedbackFormValues>> = ({
    handleBlur,
    handleChange,
    isSubmitting,
    errors,
    touched,
    isValid,
    status,
    resetForm,
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const maxW = useBreakpointValue({ base: '95%', md: '500px' });
    const isSignedIn = useIsSignedIn();
    const initialRef = React.useRef(null);
    const toast = useToast();

    useEffect(() => {
        if (status == 'submitted') {
            onClose();
            toast({
                title: 'Takk for din tilbakemelding!',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            resetForm();
        }
    }, [status]);

    if (!isSignedIn) {
        return null;
    }

    return (
        <>
            <FeedbackButton onClick={onOpen} />
            <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxW={maxW} bg="primary">
                    <ModalHeader>Gi tilbakemelding</ModalHeader>
                    <ModalCloseButton />
                    <Form>
                        <ModalBody pb={6}>
                            <BasicInput
                                ref={initialRef}
                                label="En kort oppsummering"
                                id="title"
                                placeholder="f.eks Oppgaver kan med fordel være..."
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={errors.title}
                                formControl={{ isInvalid: touched.title && !!errors.title }}
                                mb={3}
                            />
                            <FormControl isInvalid={touched.title && !!errors.title}>
                                <FormLabel>Beskrivelse</FormLabel>
                                <Textarea
                                    id="description"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={errors.description}
                                    formControl={{}}
                                    minLength={30}
                                    placeholder="Skriv din tilbakemelding"
                                    noOfLines={10}
                                    bg="white"
                                    color="black"
                                />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <BasicButton
                                disabled={!isValid || isSubmitting}
                                loadingText="Laster"
                                isLoading={isSubmitting}
                                type="submit"
                                label="Send"
                                variant="secondary"
                                mr={3}
                            />
                            <BasicButton label="Lukk" variant="primary" onClick={onClose} />
                        </ModalFooter>
                    </Form>
                </ModalContent>
            </Modal>
        </>
    );
};

const FeedbackForm = withFormik<IFeedbackFormProps, IFeedbackFormValues>({
    validate: ({ title, description }) => {
        const errors: FormikErrors<IFeedbackFormValues> = {};

        errors.title = stringLengthValidator(title, 10);
        errors.description = stringLengthValidator(description, 30);

        const hasErrors = Object.values(errors).some((value) => value !== undefined);

        return hasErrors ? errors : {};
    },
    handleSubmit: async ({ title, description }, { setSubmitting, setStatus }) => {
        setSubmitting(true);

        try {
            await postFeedback(title, description);
            setStatus('submitted');
        } catch (error) {
            console.log(error);
        }

        setSubmitting(false);
    },
    validateOnMount: true,
})(InnerFeedbackForm);

export default FeedbackForm;
