import React from 'react';
import { useBoolean, Box, Flex, CircularProgress, CircularProgressLabel, Badge } from '@chakra-ui/react';
import { SubscriptionPlan } from '../../types/Plans';
import { redirectToStripeCheckout } from '../../utils/stripe';
import BasicButton from '../atoms/Button';
import { useIsSignedIn } from '../../context/AuthProvider';
import { planValidUntil } from '../../utils/plans';

interface IPlanCardProps {
    plan: SubscriptionPlan;
    disabled: boolean;
    onBuy?: () => void;
    onSuccessUrlFallback?: string;
}

const PlanCard: React.FC<IPlanCardProps> = ({ plan, onBuy, disabled, onSuccessUrlFallback }) => {
    const [isLoading, { toggle }] = useBoolean(false);
    const isSignedIn = useIsSignedIn();

    const onBuyClick = async () => {
        toggle();
        onBuy?.();
        await redirectToStripeCheckout(plan.code, onSuccessUrlFallback);
        toggle();
    };

    const validUntilString = planValidUntil(plan);

    return (
        <Box
            _hover={{ transform: ['none', 'none', 'scale(1.02)'] }}
            w={['100%', '300px']}
            borderWidth="0px"
            borderRadius="lg"
            borderColor="white"
            boxShadow="2xl"
            overflow="hidden"
        >
            <Flex minH="150px" justify="center" py="4" bg="primaryLight">
                <CircularProgress
                    alignSelf="center"
                    size="150px"
                    min={0}
                    max={12}
                    value={plan.months}
                    thickness="4px"
                    trackColor="primary"
                    color="secondaryLight"
                >
                    <CircularProgressLabel fontSize="16px" color="secondaryLight" fontWeight="600">
                        {plan.months} måneder
                    </CircularProgressLabel>
                </CircularProgress>
            </Flex>
            <Box p="6" bg="secondaryLight" color="black">
                <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight" color="black" isTruncated>
                    {plan.months} Måneder
                </Box>
                <Box color="black">
                    {plan.price} kr
                    <Badge borderRadius="full" px="2" py="1" ml="2" bg="primary" color="white">
                        {(plan.price / plan.months).toFixed(2)} kr
                        <Box as="span" color="white" fontSize="10px">
                            / måned
                        </Box>
                    </Badge>
                </Box>
                <Box mt="1">
                    Gir deg tilgang til alt innhold helt frem til{' '}
                    <Box as="span" fontWeight="bold">
                        {validUntilString}
                    </Box>
                </Box>
                <BasicButton
                    variant="primary"
                    loadingText="Laster..."
                    isFullWidth
                    isLoading={isLoading}
                    disabled={!isSignedIn || disabled}
                    mt="4"
                    onClick={onBuyClick}
                    label="Kjøp"
                />
            </Box>
        </Box>
    );
};

export default PlanCard;
