import React from 'react';
import { Stack, Alert } from '@chakra-ui/react';
import firbeaseInstance, { isFirebaseError } from '../utils/firebase';
import BasicInput from '../components/atoms/Input';
import { Form, FormikErrors, FormikProps, withFormik } from 'formik';
import { emailValidator } from '../utils/validators';
import BasicButton from '../components/atoms/Button';

interface IResetPasswordFormValues {
    email: string;
}

interface IResetPasswordFormProps {
    onSuccess: () => void;
}

const InnerResetPasswordForm: React.FC<FormikProps<IResetPasswordFormValues>> = ({
    handleBlur,
    handleChange,
    errors,
    touched,
    isValid,
    isSubmitting,
    status,
}) => (
    <Form>
        <Stack spacing={4}>
            {status == 'api-error' && (
                <Alert hidden={status != 'api-error'} status="error" bg="red.500">
                    En ukjent feil oppsto. Prøv igjen senere.
                </Alert>
            )}
            <BasicInput
                label="E-post"
                id="email"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="f.eks ola@nordmann.no"
                isDisabled={isSubmitting}
                error={errors.email}
                formControl={{ isInvalid: touched.email && !!errors.email }}
            />
            <BasicButton
                isFullWidth
                disabled={!isValid || isSubmitting}
                isLoading={isSubmitting}
                loadingText="Nullstill passord"
                colorScheme="teal"
                type="submit"
                variant="secondary"
                label="Nullstill passord"
            />
        </Stack>
    </Form>
);

const ResetPasswordForm = withFormik<IResetPasswordFormProps, IResetPasswordFormValues>({
    validate: ({ email }) => {
        const errors: FormikErrors<IResetPasswordFormValues> = {};

        errors.email = emailValidator(email);
        const hasErrors = Object.values(errors).some((value) => value !== undefined);

        return hasErrors ? errors : {};
    },
    handleSubmit: async ({ email }, { setSubmitting, setFieldError, setStatus, props }) => {
        setSubmitting(true);
        try {
            await firbeaseInstance.auth().sendPasswordResetEmail(email);
            props.onSuccess();
        } catch (error) {
            console.log(error);
            if (isFirebaseError(error)) {
                switch (error.code) {
                    case 'auth/wrong-password':
                        setFieldError('password', 'Passordet er feil. Prøv igjen.');
                        break;
                    default:
                        setStatus('api-error');
                        break;
                }
            } else {
                setStatus('unkown-error');
            }
        }
        setSubmitting(false);
    },
    validateOnMount: true,
})(InnerResetPasswordForm);

export default ResetPasswordForm;
