import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import Button from './Button';
import React from 'react';

interface ILandingPageBoxProps {
    title: string;
    description: string;
    secondlineDescription?: string;
    onClick?: () => void;
    label?: string;
}

export const LandingPageBox: React.FC<ILandingPageBoxProps> = ({
    title,
    description,
    secondlineDescription,
    onClick,
    label = 'Utforsk tema',
}) => {
    return (
        <Box
            w="100%"
            padding="20px"
            bg="#FDF9F3"
            textColor="black"
            borderRadius="1rem"
            onClick={onClick}
            boxShadow="0 0 6px grey"
            display="flex"
            flexDirection="row"
            transitionDuration="0.2s"
            mx="0"
            justifyContent="space-between"
            _hover={{
                transform: ['none', 'none', ' none', 'none', 'scale(1.025)'],
                cursor: 'pointer',
            }}
        >
            <VStack spacing="4" justify="space-between" flex="1" align="flex-start">
                <Heading textShadow="lg" size="md" textAlign="left" fontWeight="600" fontStyle="normal">
                    {title}
                </Heading>
                <Text fontSize="16" mt="10px" lineHeight="19px">
                    {description}
                </Text>
                {secondlineDescription != undefined && (
                    <Text fontSize="12px" color="#333333" my="10px">
                        {secondlineDescription}
                    </Text>
                )}
                <Button label={label} variant="primary" />
            </VStack>
        </Box>
    );
};

export default LandingPageBox;
